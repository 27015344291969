.container {
  width: 100%;
  height: fit-content;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  z-index: 200;
}
.headerContainer {
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  background-color: rgba(21, 19, 32, 1);
  border-bottom: 1px solid rgba(149, 149, 149, 0.2);
}
.button {
  width: 190px;
  height: 44px;
  box-sizing: border-box;
  background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
    linear-gradient(92.42deg, rgba(137, 96, 255, 0) 2.03%, #8960ff 97.97%);
  border-radius: 8px;
  padding: 10px, 20px, 10px, 20px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.button:hover {
  box-shadow: 0 0 14px rgba(67, 116, 158, 0.7);
}
.buttonText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(246, 245, 247, 1);
}
.infoContainer {
  width: 100%;
  height: 71px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  background: rgba(50, 46, 74, 1);
}
.topText {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.mainText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
}
