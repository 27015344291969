.optionStylePriceContainer {
  width: 180px;
  height: 66px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: inherit;
  margin-right: 5px;
  box-sizing: border-box;
  padding-right: 10px;
  position: relative;
}
.optionStylePriceContainerLow {
  width: 180px;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: inherit;
  margin-right: 5px;
  box-sizing: border-box;
  padding-right: 10px;
  position: relative;
}
.optionStyleSaveText {
  margin-right: 7px;
  margin-top: 3px;
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  color: #ffffff;
}
.optionStyleOldPrice {
  width: 39px;
  height: 24px;
  box-sizing: border-box;
  position: relative;
  padding: 2px 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: inherit;
  margin-right: 3px;
}
.optionStyleOldPrice::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 50%;
  width: 100%;
  height: 1px;
  background-color: red;
}
.optionStyleDollar {
  width: 11px;
  height: 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.optionStyleFirstNumber {
  width: 34px;
  height: 44px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.optionStyle_AfterDot_text {
  width: fit-content;
  height: 66px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: first baseline;
  color: #ffffff;
}
.optionStyle_AfterDot_textLow {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: first baseline;
  color: #ffffff;
}
.optionStyle_AfterDotTopContainer {
  width: fit-content;
  height: 37px;
  display: flex;
  flex-flow: column nowrap;
}
.optionStyleElseNumber {
  width: 20px;
  height: 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.optionStyleText {
  width: 36px;
  height: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
}
.oldPrice {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-decoration: line-through;
  color: #c6b9ff;
}
