.ShadowPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 900;
  overflow-y: auto;
}
.ScrollContainer {
  position: relative;
  width: 365px;
  min-height: 900px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  touch-action: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 30px;
}
.ScrollContainer::-webkit-scrollbar {
  display: none;
}
.NewPaymentform {
  margin-top: 5px;
  position: relative;
  background-color: white;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  max-width: 360px;
  width: 360px;
  /* min-height: 800px;
  max-height: 95vh; */
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  touch-action: auto;
  -webkit-overflow-scrolling: touch;
}

.NewPaymentform::-webkit-scrollbar {
  display: none;
}
/* .testHRLine{
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: red;
  z-index: 1000;
  top: 0;
  right: 18px;
}
.testHRLineVert{
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: red;
  z-index: 1000;
  top: 25px;
  right: 0;
} */
.hrLine {
  width: 312px;
  height: 1px;
  background-color: rgba(232, 237, 255, 1);
  margin-top: 20px;
}

.HeaderText {
  color: var(--text, #151320);
  text-align: center;
  font-size: 12px;
  font-family: Inter;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.96px;
}
.textOrPayWithCard {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 11px;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aeacbf;
}
.textOrPayWithCard:before {
  content: "";
  height: 1px;
  position: absolute;
  left: -100px;
  background-color: #e8edff;
  width: 100%;
  top: 8px;
}
.textOrPayWithCard:after {
  content: "";
  height: 1px;
  position: absolute;
  right: -100px;
  background-color: #e8edff;
  width: 100%;
  top: 8px;
}
.securedBy {
  /* margin-top: 5px; */
  margin-bottom: 15px;
  font-size: 11px;
  line-height: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aeacbf;
}
.securedBy:before {
  content: "";
  height: 1px;
  position: absolute;
  left: -100px;
  background-color: #e8edff;
  width: 90px;
  top: 8px;
}
.securedBy:after {
  content: "";
  height: 1px;
  position: absolute;
  right: -100px;
  background-color: #e8edff;
  width: 90px;
  top: 8px;
}

.CardPayContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.cardDetailsText {
  width: 95%;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #151320;
  margin-bottom: 10px;
}

.OrderDetailsContainer {
  width: 322px;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  position: relative;
}
.OrderDetails {
  width: 80%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  text-align: center;
  font-family: Inter;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: #151320;
}
.closeOrderButton {
  width: 10px;
  height: 10px;
  cursor: pointer;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  color: #aeacbf;
  text-align: center;
  position: absolute;
  top: 6px;
  left: -5px;
}
.paymentFormCloseButton {
  width: 8px;
  height: 8px;
  object-fit: contain;
}

.googleContainerBtn {
  width: 100%;
  height: fit-content;
  /* margin-bottom: 5px; */
}

.googlePayPalContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  margin-bottom: 8px;
}
.appleContainerBtn {
  width: 100%;
  height: fit-content;
  margin-bottom: 8px;
  /* z-index: -11; */
}

.applePayButtonContainer {
  width: 90%;
  height: 43px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 8px;
}

.applePayCopyButton {
  width: 100%;
  height: 43px;
  background-color: black;
  border-radius: 7px;
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}
.applePayLogo {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 2px;
}
.applePayText {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
}

.secureContainer {
  width: 312px;
  height: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 15px;
}
.secureHr {
  width: 107px;
  height: 1px;
  background-color: rgba(232, 237, 255, 1);
}
.secureHrTwo {
  width: 50px;
  height: 1px;
  background-color: rgba(232, 237, 255, 1);
}
.secureImg {
  width: 20px;
  height: 20px;
  background-image: url("./assets/shield.png");
  background-size: 20px 20px;
}
.secureText {
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(174, 172, 191, 1);
}
.midContainer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.shieldPaymentForm {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.newSecureText {
  width: 181px;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #aeacbf;
}
.bottomDetailsContainer {
  margin-top: 10px;
  width: 100%;
  height: 64px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.textContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.textWithTrial {
  width: 290px;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #aeacbf;
}
.textWithoutTrial {
  /* width: 250px; */
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #aeacbf;
}
.payWithCardButton {
  width: 100%;
  height: 45px;
  border-radius: 100px;
  background: #39404e;
  border: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
}
.payWithCardText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.payWithCardImg {
  width: 32px;
  height: 20px;
  object-fit: contain;
  margin-left: 5px;
}
.payFastText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #151320;
  margin-top: 13px;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .NewPaymentform {
    max-width: 90%;
  }
}

@media screen and (max-width: 480px) {
  .NewPaymentform {
    max-width: 100%;
    padding: 10px;
  }
}
