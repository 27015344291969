.bottomSection {
  width: 328px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.moneyBackContainer {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.moneyBackImg {
  width: 22px;
  height: 24px;
  object-fit: contain;
  margin-right: 10px;
}

.moneyBackText {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f6f5f7;
}
.containerStatistic {
  width: 100%;
  margin-top: 20px;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.infoContainer {
  min-width: 226px;
  max-width: 320px;
  height: 140px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.topText {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.mainTextInfo {
  width: 260px;
  margin-top: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
}
.imgTitleContainer {
  width: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.imgTitle {
  width: 32px;
  height: 32px;
  object-fit: contain;
  margin-right: 5px;
}
@media (min-width: 715px) and (max-width: 1159px) {
  .bottomSection {
    margin-top: 60px;
  }
  .moneyBackContainer {
    margin-top: 20px;
  }
  .moneyBackImg {
    width: 32px;
    height: 32px;
  }
  .topText {
    font-size: 36px;
  }
  .infoContainer {
    margin-bottom: 30px;
  }
}
@media (min-width: 1160px) {
  .moneyBackContainer {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .moneyBackText {
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
  }
  .moneyBackImg {
    width: 42px;
    height: 42px;
  }
  .topText {
    font-size: 40px;
    line-height: 52px;
  }
  .infoContainer {
    margin-bottom: 40px;
  }
}
