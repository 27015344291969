.container {
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

@media (min-width: 1160px) {
  .container {
    flex-flow: row nowrap;
  }
}
