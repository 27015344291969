.container {
  margin: 0 auto;
  margin-top: 50px;
  width: 329px;
  height: fit-content;
  border-radius: 24px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 16px;
}
.headText {
  width: 296px;
  height: fit-content;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
  margin-bottom: 20px;
}
.firstContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 15px;
}

.firstStepElement {
  margin-bottom: 15px;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: flex-start;
}
.WithinMemoryOSCheck {
  width: 24px;
  height: 24px;
  background-image: url("./assets/check.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.WithinMemoryOSText {
  width: 100%;
  height: fit-content;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: rgba(197, 191, 203, 1);
}
.secondContainer {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
  padding-bottom: 30px;
}
.secondElement {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}
.secondImg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.solidText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.secondStepText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.stepTextMargin {
  margin-left: 5px;
}
.hrLine {
  display: none;
  width: 100%;
  height: 1px;
  background: rgba(45, 41, 72, 1);
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (min-width: 740px) and (max-width: 1439px) {
  .container {
    width: 630px;
  }
  .headText {
    width: 445px;
    font-size: 32px;
    line-height: 44px;
  }
  .firstContainer {
    width: 600px;
  }
  .secondContainer {
    width: 600px;
  }
  .firstStepElement {
    margin-bottom: 20px;
  }
  .hrLine {
    display: block;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 800px;
    box-shadow: 0px 16px 120px 0px rgba(137, 96, 255, 0.3);
  }
  .headText {
    margin-top: 30px;
    width: 538px;
    font-size: 40px;
    line-height: 48px;
  }
  .firstContainer {
    width: 671px;
  }
  .secondContainer {
    width: 671px;
  }
  .firstStepElement {
    margin-bottom: 20px;
  }
  .hrLine {
    display: block;
  }
}
