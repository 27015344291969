.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.landingImgMo {
  width: 300px;
  height: 300px;
  object-fit: contain;
  margin-top: 120px;
}

.topText {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #ffffff;
}
.mainText {
  width: 361px;
  height: 72px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #d1c1e5;
  margin-top: 20px;
}
.loadingText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
}
.loaderContainer {
  width: 292px;
  height: 36px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.textContainer {
  width: 100%;
  height: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.progressContainer {
  width: 100%;
  height: 8px;
  border-radius: 100px;
  background: #504b6d;
}
.progress {
  height: 8px;
  border-radius: 100px;
  background: linear-gradient(90deg, #484dff 0%, #b196ff 100%);
  transition: width 0.05s linear;
}

@media (min-width: 740px) {
}
