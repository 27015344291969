.toggleButton {
  width: 328px;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
  background-color: #443d6980;
  margin-top: 10px;
  margin-bottom: 15px;
}

.toggleButtonInner {
  margin-right: 20px;

  width: 54px;
  height: 32px;
  background-color: #ccc;
  border-radius: 72px;
  position: relative;
  transition: background-color 0.3s ease;
}

.toggleButtonInner.active {
  background-color: #03d4af;
}

.toggleButtonSwitch {
  width: 27px;
  height: 26px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  transition: transform 0.3s ease;
}

.toggleButtonInner.active .toggleButtonSwitch {
  transform: translateX(21px);
}
.toggleButtonEnabledTrialText {
  display: flex;
  flex-flow: column nowrap;
}

.toggleButtonText {
  margin-left: 25px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
}
.toggleButtonTextTop {
  margin-left: 25px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  color: #ffffff;
}
.toggleButtonTextBottom {
  margin-left: 25px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #ffffff;
}
