.PaymentFormContainer {
  position: relative;
  top: 0;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}


