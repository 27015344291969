.container {
  width: 335px;
  height: 269px;
  display: flex;
  flex-flow: column nowrap;
  margin-top: 40px;
}
.cancelDiscountTitleContainer {
  width: 335px;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.specialText {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #624aef;
}
.regularText {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f6f5f7;
}
.welcomeUserText {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.userNameText {
  background: linear-gradient(180deg, #624aef 0%, #b3a6ff 100%);
  background-clip: text;
  color: transparent;
  padding-right: 6px;
}
.subscContainer {
  margin: 0 auto;
  margin-top: 20px;
  width: 100%;
  height: 154px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    110.46deg,
    rgba(99, 191, 230, 0.1) 33.37%,
    rgba(145, 162, 255, 0.1) 63.44%
  );
  border: 2px solid rgba(32, 219, 255, 0.6);
  /* border-image-source: linear-gradient(88.59deg, #957aff 0.38%, #20dbff 100%); */
  border-radius: 16px;
}

.subscContainerText {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.subscContPlanText {
  width: 80%;
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
}
.subscContExpText {
  color: rgb(168, 168, 168);

  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

@media (min-width: 768px) and (max-width: 1439px) {
  .container {
    width: 688px;
    height: 269px;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 40px;
  }
  .cancelDiscountTitleContainer {
    width: 688px;
  }
  .subscContainerText {
    margin-bottom: 8px;
  }

  .subscContPlanText {
    margin-bottom: 10px;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 1276px;
    height: 294px;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 40px;
  }
  .cancelDiscountTitleContainer {
    width: 1200px;
  }
  .specialText {
    font-family: Inter;
    font-size: 48px;
    font-weight: 700;
    line-height: 70px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #624aef;
  }
  .regularText {
    font-family: Inter;
    font-size: 48px;
    font-weight: 700;
    line-height: 70px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #f6f5f7;
  }
  .welcomeUserText {
    font-size: 48px;
    line-height: 70px;
  }
  .subscContainerText {
    font-size: 16px;
  }

  .subscContPlanText {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 48px;
  }
  .subscContainer {
    height: 200px;
  }
}
