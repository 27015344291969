.HasActiveSubscModalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(129, 129, 129, 0.8);
  z-index: 10000;
}
.HasActiveSubscModal {
  margin: 0 auto;
  margin-top: 10%;
  width: 95%;
  height: 480px;
  padding-bottom: 40px;
  background: linear-gradient(180deg, #3e3a54 0%, #120b3b 100%);
  border-radius: 12px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  overflow-y: scroll;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
}

.HasActiveSubscModal::-webkit-scrollbar {
  display: none;
}
.closeButton {
  position: absolute;
  top: 18px;
  left: 18px;
  width: 15px;
  height: 15px;
  background-color: initial;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.closeImg {
  width: 15px;
  height: 15px;
  object-fit: contain;
}
.HasActiveSubscModalContainer {
  width: 95%;
  padding-top: 30px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.HasActiveSubscModalLogo {
  width: 108px;
  height: 32px;
  margin-top: 50px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.HasActiveSubscModalText {
  width: 95%;
  height: 100%;
  margin-top: 25px;
  color: var(--white, #fff);
  text-align: left;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.HasActiveSubscModalButton {
  margin-top: 40px;
  width: 80%;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  background: var(--primary, #1a4bff);
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.HasActiveSubscModalButtonDisable {
  margin-top: 40px;
  width: 80%;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  background: var(--primary, #8293cd);
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
.HasActiveSubscModalButton:hover {
  background: #1745ea;
}
.HasActiveSubscModalButton:active {
  background: #204ffa;
}
@media screen and (min-width: 500px) {
  .HasActiveSubscModal {
    width: 400px;
  }
}
