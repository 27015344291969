.mainSection {
  width: 328px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.greenBlock {
  width: 57px;
  height: 20px;
  background: #4beeb3;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.greenBlockText {
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #151320;
}
.mainTextBlock {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.mainTopTextSection {
  width: 190px;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.mainCrossedText {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  text-decoration-line: line-through;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #e04242;
}
.mainText {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.mainBottomText {
  width: 250px;

  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin-top: 10px;
}
.buttonContainer {
  margin-top: 20px;
}
@media (min-width: 715px) and (max-width: 1159px) {
  .mainSection {
    margin-top: 40px;
  }
  .greenBlock {
    width: 67px;
    height: 23px;
  }
  .greenBlockText {
    font-size: 12px;
    line-height: 14.52px;
  }

  .mainTextBlock {
    margin-top: 20px;
  }
  .mainTopTextSection {
    width: 212px;
  }
  .mainCrossedText {
    line-height: 40px;
  }
  .mainText {
    line-height: 40px;
    font-weight: 700;
    font-size: 28px;
  }
  .mainBottomText {
    width: 250px;
    margin-top: 10px;
  }
  .buttonContainer {
    margin-top: 40px;
  }
}
@media (min-width: 1160px) {
  .mainSection {
    margin-top: 40px;
  }
  .greenBlock {
    width: 67px;
    height: 23px;
  }
  .greenBlockText {
    font-size: 12px;
    line-height: 14.52px;
  }

  .mainTextBlock {
    margin-top: 25px;
  }
  .mainTopTextSection {
    width: 260px;
  }
  .mainCrossedText {
    font-size: 32px;
    line-height: 42px;
  }
  .mainText {
    font-size: 32px;
    font-weight: 700;
    line-height: 42px;
  }
  .mainBottomText {
    width: 250px;
    margin-top: 10px;
    font-size: 16px;
  }
  .buttonContainer {
    margin-top: 50px;
  }
}
