.container {
  margin: 0 auto;
  width: 112px;
  height: 20px;
  box-sizing: border-box;
  margin-top: 20px;
  background-image: url(./assets/newQuizLogo.png);
  background-size: 112px 20px;
  background-repeat: no-repeat;
  background-position: 50%;
}
@media (min-width: 740px) {
  .container {
    width: 167px;
    height: 30px;
    background-size: 167px 30px;
    margin-top: 50px;
  }
}
