.PlanContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 25px;
  padding-bottom: 5px;
}
.OptionsPlanContainer {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 20px;
  color: rgba(174, 172, 191, 1);
}
