.container {
  width: 64px;
  height: fit-content;
  cursor: pointer;
  border: none;
  background-color: inherit;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.imgArrow {
  background-image: url(./assets/arrowNarrowLeft.png);
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
}
.textBack {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
}
