.emailBlockContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.emailBlock {
  width: 100%;
  height: fit-content;
  justify-content: space-evenly;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}

.inputBlock {
  display: flex;
  width: 251px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--blue-10, #e8edff);
  background: var(--white, #fff);
  color: var(--grey, #72717d);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-right: 5px;
}
.inputBlock:focus {
  border: 1px solid #02beb2;
}
.inputBlock.invalid {
  border-color: red;
}
.saveBtn.invalid {
  border: 1px solid red;
}
.invalidText {
  margin-left: 90px;
  color: #ed0006;
  text-align: right;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  line-height: 20px;
}

.saveBtn {
  box-sizing: border-box;
  display: flex;
  width: 59px;
  height: 40px;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  background: var(--blue-10, #e8edff);
  cursor: pointer;
}
.buttonText {
  color: var(--dark-grey, #696680);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.disBlockTop {
  position: absolute;
  top: 6%;
  width: 100%;
  height: 62%;
  border-radius: inherit;
  z-index: 100;
}

.disBlockBot {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 18%;
  border-radius: inherit;
  z-index: 100;
}
.considerText {
  margin: 0 auto;
  margin-top: 8px;
  color: var(--grey, #aeacbf);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
@media (min-width: 740px) and (max-width: 1439px) {
  .inputBlock {
    width: 350px;
  }
  .saveBtn {
    width: 168px;
  }
  .buttonText {
    font-size: 14px;
  }
}

@media (min-width: 1440px) {
  .inputBlock {
    width: 350px;
  }
  .saveBtn {
    width: 168px;
  }
  .buttonText {
    font-size: 14px;
  }
}
