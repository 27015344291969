.FooterContainer {
  margin: 0 auto;
  margin-top: 20px;
  width: 100%;
  height: fit-content;
  background-color: rgba(21, 19, 32, 1);
  background-color: rgba(21, 19, 32, 1);
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  z-index: 0;
}
.logoPosition {
  margin: 0 auto;
  width: 329px;
  height: fit-content;
}
.footerHeader {
  width: 108px;
  height: 32px;
  background-size: 108px 32px;
  background-repeat: no-repeat;
}

.FooterContainerTextMisson {
  margin: 0 auto;
  margin-top: 24px;
  width: 329px;
  height: fit-content;
  box-sizing: border-box;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.FooterContainerOtherText {
  margin: 0 auto;
  width: 329px;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 40px;
}
.FooterPolicyGroup {
  width: fit-content;
  display: flex;
  flex-flow: column nowrap;
  line-height: 20px;
}
a {
  width: fit-content;
}
.FooterContactGroup {
  margin-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  width: fit-content;
}
.hrLine {
  width: 100%;
  height: 2px;
  background: rgba(177, 150, 255, 0.1);
  margin-top: 30px;
}
.FooterCopyright {
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  width: 329px;
  display: flex;
  justify-content: flex-start;
}
.FooterContainerText {
  width: fit-content;
  height: fit-content;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.FooterCopyrightText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(162, 159, 194, 1);
}
.FooterContainerTextLink {
  margin-bottom: 19px;
}
.TelMail {
  margin-bottom: 19px;

  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(162, 159, 194, 1);
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

@media (min-width: 740px) and (max-width: 1439px) {
  .FooterContainer {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .logoPosition {
    margin: 0;
    margin-left: 70px;
  }

  .FooterContainerTextMisson {
    margin: 0;
    margin-top: 30px;
    margin-bottom: 40px;
    margin-left: 70px;

    width: 329px;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }
  .FooterContainerOtherText {
    margin: 0;
    margin-left: 70px;

    width: 500px;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .FooterContactGroup {
    margin: 0;
    margin-left: 80px;
    justify-content: center;
    align-items: flex-start;
  }
  .FooterCopyright {
    margin: 0;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 70px;
  }
  .FooterContainerTextLink {
    margin-bottom: 24px;
  }
  .TelMail {
    margin-bottom: 24px;
  }
}
@media (min-width: 1440px) {
  .FooterContainer {
    margin-top: 70px;
    justify-content: center;
    align-items: center;
  }
  .logoPosition {
    margin: 0 auto;
    width: fit-content;
    height: fit-content;
  }
  .FooterContainerTextMisson {
    margin-top: 50px;
    margin-bottom: 20px;
    width: 535px;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
  .FooterContainerOtherText {
    margin: 0;
    margin-top: 40px;
    width: 200px;
    align-items: center;
    justify-content: center;
  }
  .FooterPolicyGroup {
    justify-content: center;
    align-items: center;
  }
  .FooterContactGroup {
    margin-top: 30px;
    justify-content: center;
    align-items: center;
  }
}
