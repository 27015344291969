* {
  box-sizing: border-box;
  /* user-select: none;
  -webkit-user-select: none; */
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background: #151320 url("/public/img/bg.webp") no-repeat 0 0;
  font-size: 16px;
}

.hidden {
  display: none;
}

/* input[type="text"],
textarea {
  user-select: auto;
  -webkit-user-select: auto;
  pointer-events: auto;
} */
