.starContainer {
  width: 96px;
  height: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 4px;
}
.star {
  width: 16px;
  height: 16px;
  padding: 1.67px, 1.06px, 1.64px, 1.06px;
}
