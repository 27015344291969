.elementContainer {
  width: 329px;
  height: 100px;
  border-radius: 8px;
  background: linear-gradient(
    253.49deg,
    rgba(68, 61, 105, 0.4) 0%,
    rgba(50, 45, 79, 0.4) 100%
  );
  border: 1px solid #7268a5;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.elementContainerActive {
  background: linear-gradient(96.39deg, #024960 23.99%, #413186 79.2%);
  border: 4px solid #ffffff;
}

.mostPopular {
  position: absolute;
  top: -14px;
  width: 125px;
  height: 25px;
  border-radius: 8px;
  background: #665b8f;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.mostPopularText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
}
.optionContainer {
  width: 206px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 12px;
  box-sizing: border-box;
}
.optionHeaderContainer {
  width: 206px;
  height: fit-content;
}
.optionHeaderText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
}

.saveTextParts {
  width: 210px;
  height: inherit;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-top: 2px;
}

.partText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  margin-right: 3px;
}

.blockSaveText {
  margin-top: 4px;
  width: 60px;
  height: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.partTextGreen {
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;
}
