.container {
  width: 100%;
  height: 88px;
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.featuredText {
  width: fit-content;
  height: fit-content;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.2em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
  margin-bottom: 20px;
}

.imgContainer {
  width: 329px;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
}

.img {
  min-width: 80px;
  max-width: 118px;
  height: 16px;
  object-fit: contain;
  margin-bottom: 20px;
}

@media (min-width: 740px) and (max-width: 1439px) {
  .container {
    width: 100%;
    height: 150px;
  }
  .featuredText {
    font-size: 15px;
  }

  .imgContainer {
    width: 600px;
    height: fit-content;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .img {
    min-width: 180px;
    min-height: 30px;
    object-fit: contain;
    margin: 10px;
  }
}
@media (min-width: 1440px) {
  .container {
    height: 150px;
  }
  .featuredText {
    font-size: 15px;
  }
  .imgContainer {
    width: 600px;
    height: fit-content;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .img {
    min-width: 180px;
    min-height: 30px;
    object-fit: contain;
    margin: 10px;
  }
}
