.container {
  margin: 0 auto;
  width: 362px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.imgJonas {
  width: 108px;
  height: 108px;
  border-radius: 50%;
  margin-top: 20px;
}
.title {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
}
.subTitle {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #d1c1e5;
  margin-top: 15px;
}
.hr {
  width: 100%;
  border: 2px solid #b196ff;
  border-radius: 10px;
  margin-top: 20px;
}

.mainContainer {
  width: 353px;
  height: 168px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 20px;
}
.element {
  width: 312px;
  height: 24px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.elementImg {
  width: 24px;
  height: 21px;
  object-fit: contain;
  margin-right: 8px;
}
.elementText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}
.button {
  width: 361px;
  height: 48px;
  border-radius: 100px;
  padding: 0;
  background: #484dff;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  margin-top: 50px;
}
.buttonText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}

@media (min-width: 760px) {
  .container {
    width: 500px;
  }
  .subTitle {
    width: 440px;
  }
}
