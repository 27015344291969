.container {
  margin: 0 auto;
  min-width: 328px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
}
.title {
  width: 328px;
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 43.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f6f5f7;
}

.elementsContainer {
  width: 328px;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.element {
  width: 328px;
  height: 294px;
  margin-bottom: 40px;
  border-radius: 24px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.elementImg {
  width: 82px;
  height: 82px;
  object-fit: contain;
  margin-top: 30px;
}
.elementTitle {
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f6f5f7;
  margin-top: 30px;
}
.elementText {
  width: 244px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #c5bfcb;
  margin-top: 5px;
}
@media (min-width: 715px) and (max-width: 1159px) {
  .title {
    width: 600px;
  }
  .elementsContainer {
    width: 712px;
  }
  .element {
    width: 712px;
    height: 270px;
  }
  .elementImg {
    margin-top: 30px;
  }
  .elementTitle {
    margin-top: 20px;
  }
  .elementText {
    width: 270px;
  }
}
@media (min-width: 1160px) {
  .title {
    width: 700px;
  }
  .elementsContainer {
    width: 1160px;
    justify-content: space-between;
  }
  .element {
    width: 365px;
    height: 295px;
  }
  .elementText {
    width: 270px;
  }
}
