.bigContainer {
  margin-top: 20px;
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
}
.ChoosePlanContainer {
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 329px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
}
.HeaderText {
  width: 100%;
  height: fit-content;
  color: var(--white, #fff);
  text-align: center;
  font-size: 24px;
  font-family: Inter;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 8px;
}
/* @media (min-width: 1440px) {
  .bigContainer {
    height: 700px;
  }
} */
