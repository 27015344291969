.containerPayment {
  box-sizing: border-box;
  width: 65%;
  height: 17px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
