.container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.contentContainer {
  width: 345px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.header {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: #f6f5f7;
  width: 347px;
}
.secureContainer {
  width: 202px;
  height: 32px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.secureImg {
  width: 32px;
  height: 32px;
  object-fit: contain;
}
.secureText {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #19ffd6;
}
.mainContainer {
  margin-top: 30px;
  width: 345px;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  box-sizing: border-box;
}
.imgContainer {
  width: 32px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.roundFrame {
  width: 32px;
  height: 32px;
  object-fit: contain;
}
.stripFrame {
  width: 8px;
  height: 65px;
}
.partStripOneFrame {
  width: 8px;
  height: 33px;
}
.partStripTwoFrame {
  width: 8px;
  height: 33px;
}

.mainPart {
  width: 297px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-start;
}

.mainTextPart {
  width: 297px;
  height: 96px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding-top: 6px;
}
.partHeader {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}
.partMain {
  margin-top: 5px;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #c8c8c8;
}
.footer {
  width: auto;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.footerPolicyGroup {
  width: 345px;
  height: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 40px;
}

.footerContainerText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #e8edff;
}
a {
  width: fit-content;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.footerCopyrightText {
  margin-top: 10px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #e8edff;
}

@media (min-width: 744px) {
  .contentContainer {
    width: 412px;
    height: fit-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .mainContainer {
    width: 412px;
  }

  .stripFrame {
    height: 91px;
  }
  .partStripOneFrame {
    height: 46px;
  }
  .partStripTwoFrame {
    height: 46px;
  }

  .mainPart {
    width: 364px;
  }

  .mainTextPart {
    width: 364px;
    height: 122px;
  }

  .partHeader {
    font-size: 18px;
    line-height: 24px;
  }
  .partMain {
    font-size: 16px;
    line-height: 24px;
  }
}
