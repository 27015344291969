.doubleComponentStyle {
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

@media screen and (min-width: 1440px) {
  .doubleComponentStyle {
    flex-flow: row nowrap;
  }
}
