.SwiperContainer {
  width: 100%;
  height: max-content;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 100px;
  overflow: hidden;
}

.feedbackContainer {
  width: 329px;
  height: 400px;
  border-radius: 16px;
  box-sizing: border-box;
  padding: 20px;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;

  background: linear-gradient(253.49deg, #322e48 0%, #322d4f 100%),
    linear-gradient(
      252.29deg,
      rgba(93, 79, 172, 0.5) 0%,
      rgba(255, 255, 255, 0) 98.51%
    );

  position: relative;
}
.topContent {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column wrap;
}
.botContent {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
}
.sourceImg {
  width: 32px;
  height: 32px;
}
.feedback {
  width: 100%;
  margin-top: 15px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.userName {
  width: 100%;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.buttonContainer {
  position: absolute;
  width: 100px;
  height: fit-content;
  z-index: 20;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  bottom: 0;
  right: 50px;
}
.buttonArrowL {
  width: 44px;
  height: 44px;
  border-radius: 50%;

  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: linear-gradient(253.49deg, #3a2e7d 0%, #322d4f 100%),
    linear-gradient(
      252.29deg,
      rgba(93, 79, 172, 0.5) 0%,
      rgba(255, 255, 255, 0) 98.51%
    );

  border: 1px solid rgba(93, 79, 172, 0.5);
  transition: 0.5s;
}
.buttonArrowL:hover {
  background: linear-gradient(253.49deg, #3a2e7d 0%, #7266b4 100%),
    linear-gradient(
      252.29deg,
      rgba(93, 79, 172, 0.5) 0%,
      rgba(255, 255, 255, 0) 98.51%
    );
}
.buttonArrowR {
  width: 44px;
  height: 44px;
  border-radius: 50%;

  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background: linear-gradient(253.49deg, #3a2e7d 0%, #322d4f 100%),
    linear-gradient(
      252.29deg,
      rgba(93, 79, 172, 0.5) 0%,
      rgba(255, 255, 255, 0) 98.51%
    );

  border: 1px solid rgba(93, 79, 172, 0.5);
  transition: 0.5s;
}
.buttonArrowR:hover {
  background: linear-gradient(253.49deg, #7266b4 0%, #322d4f 100%),
    linear-gradient(
      252.29deg,
      rgba(93, 79, 172, 0.5) 0%,
      rgba(255, 255, 255, 0) 98.51%
    );
}
.arrowImg {
  width: 8px;
  height: 15px;
}
.shadowRightCont {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 40%;
  height: 100%;
  background: linear-gradient(
    270deg,
    #151320 6.49%,
    rgba(21, 19, 32, 0) 95.19%
  );
  z-index: 10;
}
.shadowLeftCont {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
  height: 100%;
  rotate: 180deg;
  background: linear-gradient(
    270deg,
    #151320 6.49%,
    rgba(21, 19, 32, 0) 95.19%
  );
  z-index: 10;
}
@media (min-width: 740px) and (max-width: 1439px) {
  .buttonContainer {
    right: 200px;
  }
  .shadowLeftCont {
    display: none;
  }
  .shadowRightCont {
    display: none;
  }
  .SwiperContainer {
    height: 720px;
  }
}

@media (min-width: 1440px) {
  .buttonContainer {
    right: 200px;
  }
  .shadowLeftCont {
    display: block;
  }
  .shadowRightCont {
    display: block;
  }
  .SwiperContainer {
    height: 800px;
  }
}
