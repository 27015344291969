.container {
  margin: 0 auto;
  width: 361px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
}
.title {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #ffffff;
}
.subTitle {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #b196ff;
  margin-top: 10px;
}
.mainContainer {
  width: 361px;
  height: 124px;
  border-radius: 8px;
  border: 2px solid #ffffff;
  background: linear-gradient(91.08deg, #e9e2ff 3.55%, #c6b3fc 79.87%);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 30px;
}
.topText {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #151320;
}
.mainText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #5b5097;
}
.imgHeader {
  text-transform: uppercase;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #d1c1e5;
  margin-top: 30px;
}
.imgContainer {
  width: 331px;
  height: 170px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: space-evenly;
  margin-top: 20px;
}
.imgTheTimes {
  width: 185px;
  height: 22px;
  object-fit: contain;
}
.imgBbc {
  width: 92px;
  height: 26px;
  object-fit: contain;
}
.imgTheTelegraph {
  width: 166px;
  height: 27px;
  object-fit: contain;
}
.imgTechCranch {
  width: 135px;
  height: 20px;
  object-fit: contain;
}
.imgTheNewYorkTimes {
  width: 184px;
  height: 24px;
  object-fit: contain;
}
.imgIndependent {
  width: 154px;
  height: 15px;
  object-fit: contain;
}
.button {
  width: 361px;
  height: 48px;
  border-radius: 100px;
  padding: 0;
  background: #484dff;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  margin-top: 40px;
}
.buttonText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}
@media (min-width: 760px) {
  .container {
    width: 500px;
  }
  .mainContainer{
    width: 475px;
  }
  .topText{
    width: 460px;
  }
  .imgHeader{
    margin-top: 40px;
  }
  .imgContainer{
    margin-top: 30px;
    width: 100%;
  }
}
