.header {
  margin: 0 auto;
  width: 100%;
  height: 66px;
  background-color: #151320e5;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #b196ff1a;
  box-sizing: border-box;
}
@media (min-width: 768px) and (max-width: 1439px) {
  .header {
    justify-content: flex-start;
    padding-left: 100px;
  }
}
@media (min-width: 1439px) {
  .header {
    height: 90px;
    justify-content: flex-start;
    padding-left: 100px;
  }
}
