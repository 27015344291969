.ImgModuleContainer {
  margin: 0 auto;
  position: relative;
  width: 361px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  box-sizing: border-box;
  background: linear-gradient(to right, rgb(35, 29, 63), rgb(69, 61, 104));
  overflow: hidden;
  position: relative;
  border-right: 2px solid rgb(102, 96, 129);
  padding-bottom: 20px;
}
.ImgModuleContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgb(35, 29, 63), rgb(102, 96, 129));
}
.ImgModuleContainer::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgb(35, 29, 63), rgb(102, 96, 129));
}
.headerContainer {
  width: 95%;
  height: 33px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: rgba(80, 70, 129, 1);
  color: rgba(246, 245, 247, 1);
  margin-top: 10px;
}
.headerText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  margin-right: 5px;
}
.mainContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-top: 20px;
}
.imgContainer {
  width: 180px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.headLeftCont {
  width: 50px;
  height: 28px;
  box-sizing: border-box;
  border-radius: 8px;
  background: rgba(66, 60, 97, 1);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.headLeftText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(246, 245, 247, 1);
}
.headRightCont {
  width: 91px;
  height: 28px;
  border-radius: 8px;
  background: rgba(75, 238, 179, 1);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.headRightText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(21, 19, 32, 1);
}
.botTextCont {
  width: 146px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
}
.botTitle {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.botMain {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
  margin-top: 5px;
}
.brainImg {
  width: 140px;
  height: 148px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.arrowsImg {
  width: 38px;
  height: 42px;
}
@media (min-width: 740px) and (max-width: 1439px) {
  .ImgModuleContainer {
    width: 600px;
  }
  .headerText {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    margin-right: 5px;
  }
  .imgContainer {
    width: 200px;
  }

  .botTextCont {
    width: 189px;
  }
  .botTitle {
    font-size: 16px;
  }
  .botMain {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(246, 245, 247, 1);
    margin-top: 5px;
  }
  .arrowsImg {
    width: 108px;
    height: 119px;
  }
}
@media (min-width: 1440px) {
  .ImgModuleContainer {
    width: 600px;
  }
  .headerText {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    margin-right: 5px;
  }
  .imgContainer {
    width: 200px;
  }

  .botTextCont {
    width: 189px;
  }
  .botTitle {
    font-size: 16px;
  }
  .botMain {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(246, 245, 247, 1);
    margin-top: 5px;
  }
  .arrowsImg {
    width: 108px;
    height: 119px;
  }
}
