.Footer {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.FooterText {
  width: 260px;
  height: fit-content;

  color: #aeacbf;
  margin-top: 10px;

  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
}
