.container {
  margin: 0 auto;
  width: 330px;
  height: fit-content;
  margin-top: 50px;
  margin-bottom: 50px;

  background: linear-gradient(
      0deg,
      rgba(21, 19, 32, 0.5),
      rgba(21, 19, 32, 0.5)
    ),
    linear-gradient(90deg, rgba(41, 31, 75, 0.25) 0%, #151320 68.01%),
    linear-gradient(
      252.29deg,
      rgba(93, 79, 172, 0.3) 0%,
      rgba(255, 255, 255, 0) 98.51%
    );

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;

  border-radius: 12px;
  border-right: 1px solid rgba(102, 96, 129, 0.7);
  border-top: 1px solid rgba(102, 96, 129, 0.5);
  border-bottom: 1px solid rgba(102, 96, 129, 0.5);
  border-left: 1px solid rgba(98, 94, 116, 0.3);

  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
}

.img {
  width: 131px;
  height: 112px;
}

.headerText {
  margin-top: 20px;
  margin-bottom: 15px;
  width: 320px;
  height: fit-content;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}

.FooterContainerText {
  width: fit-content;
  max-width: 90%;
  display: flex;
  flex-flow: column wrap;
  box-sizing: border-box;
  padding-bottom: 15px;
}
.FooterText {
  width: 98%;
  margin-top: 10px;
  color: var(--grey, #aeacbf);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}
.FooterSpecialText {
  text-align: center;
  margin-top: 10px;
  color: var(--grey, #aeacbf);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}
.subscrText {
  color: #928fb2;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-decoration-line: underline;
}

@media (min-width: 740px) and (max-width: 1439px) {
  .container {
    width: 443px;
  }
  .headerText {
    width: 100%;
  }
  .FooterContainerText {
    width: 379px;
  }
}

@media (min-width: 1440px) {
  .container {
    width: 696px;
  }
  .headerText {
    width: 100%;
  }
  .FooterContainerText {
    width: 480px;
  }
}
