.textContainer {
  margin: 0 auto;
  margin-top: 20px;
  width: 320px;
  min-height: 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.text {
  color: var(--blue-10, #e8edff);
  text-align: center;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
