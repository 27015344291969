.container {
  margin: 0 auto;
  width: 361px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}

.title {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #ffffff;
  margin-top: 50px;
}

.subTitle {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #b196ff;
  margin-top: 10px;
}
.answerButtonsContainer {
  width: 100%;
  height: 322px;
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
}

.answerButton {
  width: 171px;
  height: 152px;
  /* border: 2px solid #484dff; */
  border: none;
  border-radius: 10px;
  padding: 0;
  background-color: inherit;
  cursor: pointer;
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.buttonImg {
  width: 170px;

  height: 110px;
  object-fit: cover;
}
.textContainer {
  width: 172px;
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #484dff;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}
.answerText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  margin-left: 16px;
}

.arrowRight {
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: 8px;
}
