.container {
  margin: 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
}
.headerButton {
  width: 258px;
  height: 44px;
  padding: 10px, 30px, 10px, 30px;
  border-radius: 16px;
  background: linear-gradient(
      72.72deg,
      rgba(115, 13, 13, 0.95) 0%,
      rgba(28, 31, 85, 0.95) 100%,
      rgba(28, 31, 85, 0.95) 100%
    ),
    linear-gradient(
      89.88deg,
      rgba(255, 255, 255, 0) 0.1%,
      rgba(255, 255, 255, 0.2) 99.9%
    );
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.headerButton:hover {
  background: linear-gradient(
      72.72deg,
      rgba(166, 20, 20, 0.95) 0%,
      rgba(39, 43, 106, 0.95) 100%,
      rgba(40, 44, 122, 0.95) 100%
    ),
    linear-gradient(
      89.88deg,
      rgba(255, 255, 255, 0) 0.1%,
      rgba(255, 255, 255, 0.2) 99.9%
    );
  /* background: linear-gradient(
      72.72deg,
      rgba(39, 43, 106, 0.95) 0%,
      rgba(166, 20, 20, 0.95) 100%,
      rgba(40, 44, 122, 0.95) 100%
    ),
    linear-gradient(
      89.88deg,
      rgba(255, 255, 255, 0) 0.1%,
      rgba(255, 255, 255, 0.2) 99.9%
    ); */
}
.headerBtnText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.header {
  margin-top: 50px;
  font-family: Inter;
  font-size: 33px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.containerStatistic {
  width: 100%;
  margin-top: 20px;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.infoContainer {
  min-width: 226px;
  max-width: 320px;
  height: 140px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.topText {
  font-family: Inter;
  font-size: 36px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.mainTextInfo {
  width: 260px;
  margin-top: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
}

.mainTextContainer {
  width: 329px;
  height: 95px;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 8px;
  white-space: wrap;
  text-align: center;
}
.mainText {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}

.mainTextBold {
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.mainBtnContainer {
  position: relative;
  width: 350px;
  min-height: 145px;
  height: fit-content;
  margin-top: 30px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.button {
  position: relative;
  width: 251px;
  height: 52px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 12px, 24px, 12px, 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
    linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8960ff 100%);
  z-index: 0;
}
.button:hover {
  box-shadow: 0 0 42px rgba(67, 116, 158, 0.7);
}
.buttonText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(246, 245, 247, 1);
}
.underBtnText {
  margin-top: 30px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
  text-decoration: underline;
  cursor: pointer;
}
.btnTextContainer {
  width: 251px;
  height: 52px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 12px, 24px, 12px, 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
    linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8960ff 100%);
  z-index: 300;
}
.starOneL,
.starOneR {
  width: 80px;
  height: 80px;
  background-size: 80px 80px;
  background-repeat: no-repeat;
}
.starTwoL,
.starTwoR,
.starThreeL,
.starThreeR {
  width: 40px;
  height: 40px;
  background-size: 40px 40px;
  background-repeat: no-repeat;
}
.starOneL,
.starTwoL,
.starThreeL,
.starOneR,
.starTwoR,
.starThreeR {
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s ease-out, transform 0.2s ease-out;
}

.starOneL {
  top: -55px;
  left: -57px;
  background-image: url("./assets/star.png");
  transform: translateX(35px) translateY(30px) rotate(20deg);
  z-index: -10;
}
.starTwoL {
  top: -32px;
  left: 8px;
  background-image: url("./assets/star.png");
  transform: translateX(30px) translateY(22px) rotate(-120deg);
}

.starThreeL {
  top: 3px;
  left: -33px;
  background-image: url("./assets/star.png");
  transform: translateX(30px) translateY(10px) rotate(180deg);
}

.starOneR {
  bottom: -58px;
  right: -50px;
  background-image: url("./assets/star.png");
  transform: translateX(-35px) translateY(-30px) rotate(20deg);
}
.starTwoR {
  bottom: -35px;
  right: 6px;
  background-image: url("./assets/star.png");
  transform: translateX(-30px) translateY(-22px) rotate(-120deg);
}
.starThreeR {
  bottom: -5px;
  right: -35px;
  background-image: url("./assets/star.png");
  transform: translateX(-30px) translateY(-10px) rotate(180deg);
}

.button:hover .starOneL,
.button:hover .starTwoL,
.button:hover .starThreeL,
.button:hover .starOneR,
.button:hover .starTwoR,
.button:hover .starThreeR {
  opacity: 1;
  transform: translateX(0);
}

.footerText {
  width: 310px;
  margin-top: 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
}
.bottomText {
  width: 284px;
  height: fit-content;
  margin-top: 40px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #c5bfcb;
  margin-bottom: 20px;
}
.bottomTextSpecial {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-decoration-line: line-through;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ff5900;
}
@media (min-width: 630px) and (max-width: 1439px) {
  .header {
    width: 540px;
    font-family: Inter;
    font-size: 58px;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(246, 245, 247, 1);
  }
  .mainTextContainer {
    width: 500px;
    height: 70px;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 8px;
    white-space: wrap;
    text-align: center;
  }
  .footerText {
    margin-top: 30px;
    width: 495px;
    height: 48px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(197, 191, 203, 1);
  }
  .bottomText {
    width: 508px;
  }
}

@media (min-width: 1440px) {
  .header {
    font-family: Inter;
    font-size: 58px;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(246, 245, 247, 1);
  }
  .mainTextContainer {
    width: 636px;
    height: 70px;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 8px;
    white-space: wrap;
    text-align: center;
  }
  .footerText {
    margin-top: 50px;
    width: 495px;
    height: 48px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(197, 191, 203, 1);
  }
  .bottomText {
    width: 646px;
  }
}
