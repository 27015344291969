.mainContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-top: 20px;
}
.imgContainer {
  width: 180px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.brainImg {
  width: 140px;
  height: 148px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.arrowsImg {
  width: 38px;
  height: 42px;
}
@media (min-width: 740px) and (max-width: 1439px) {
  .imgContainer {
    width: 200px;
  }

  .arrowsImg {
    width: 108px;
    height: 119px;
  }
}
@media (min-width: 1440px) {
  .imgContainer {
    width: 200px;
  }

  .arrowsImg {
    width: 108px;
    height: 119px;
  }
}
