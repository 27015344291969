.container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #3d3b56;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: background-color 0.3s ease-in-out;
}

.circle.active {
  background-color: #fff;
}

.line {
  margin-left: 3px;
  margin-right: 3px;
  width: 101px;
  height: 2px;
  border-radius: 10px;
  background-color: #3d3b56;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.lineProgress {
  border-radius: 10px;
  height: 100%;
  background-color: #fff;
  transition: width 0.3s ease-in-out;
}
