.container {
  margin: 0 auto;
  margin-top: 20px;
  width: 329px;
  height: fit-content;
  box-sizing: border-box;
  padding-bottom: 15px;
  padding-top: 19px;

  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
.imgPeople {
  width: 93px;
  height: 25px;
  background-image: url(../../../assets/Frame_users.png);
  background-size: 93px 25px;
  background-repeat: no-repeat;
  margin-bottom: 14px;
}
.bigText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.smallText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
}

@media (min-width: 1440px) {
  .container {
    width: 421px;
  }
  .text {
    font-size: 16px;
  }
}
