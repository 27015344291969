.ChoosePlanContainer {
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 20px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.optionsCont {
  width: 329px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.HeaderText {
  width: 100%;
  height: 40px;
  color: var(--white, #fff);
  text-align: center;
  font-size: 24px;
  font-family: Inter;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 40px;
  box-sizing: border-box;
}

.Footer {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  box-sizing: border-box;
  padding-bottom: 10px;
}
.FooterText {
  width: 207px;
  height: fit-content;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #aeacbf;
  margin-top: 10px;
}

.FooterSafe {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 4px 8px;
  gap: 8px;
  width: 221px;
  height: 28px;
  background: rgba(75, 238, 179, 0.2);
  border-radius: 8px;
}
.FooterSafeIcon {
  width: 20px;
  height: 20px;
  background-image: url(./assets/Icon.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.FooterSafeText {
  width: 180px;
  height: 16px;
  color: var(--green, #4beeb3);
  font-size: 12px;
  font-family: Inter;
  line-height: 16px;
}
