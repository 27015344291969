.errContainer {
  position: fixed;
  z-index: 1000;
  bottom: 10px;
  left: 10px;
  box-sizing: border-box;
  min-width: 100px;
  width: fit-content;
  min-height: 35px;
  height: fit-content;
  background-color: rgb(131, 8, 8);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
}

.errText {
  color: whitesmoke;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
}
