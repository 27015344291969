.topBlock {
  width: 328px;
  height: 103px;
  border-radius: 16px;
  border: 1.5px solid #465089;
  background: #242c5b80;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.topBlockTitle {
  width: 100%;
  height: fit-content;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  padding-left: 15px;
  margin-bottom: 10px;
}
.topBlockMain {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding-left: 15px;
}
.topBlockParts {
  width: 106px;
  height: 38px;
  display: flex;
  flex-flow: column nowrap;
}
.topBlockPartsTitle {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin-bottom: 3px;
}
.topBlockLeftPartImgCont {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.topBlockLeftPartImg {
  width: 16px;
  height: 16px;
  object-fit: contain;
  margin-right: 5px;
}
.topBlockLeftPartText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
