.container {
  margin: 0 auto;
  width: 329px;
  height: 265px;
  margin-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  border-radius: 14px;
  box-sizing: border-box;
  position: relative;
  z-index: 10;
}
.headText {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.backgroundImg {
  display: none;
  width: 100%;
  height: 316px;
  background-image: url("./assets/What_is_MemoryOS_background.png");
  background-repeat: no-repeat;
}
.videoContainer {
  width: 329px;
  height: 185px;
  margin-top: 60px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 16px 60px 0px rgba(137, 96, 255, 0.3);
  border-radius: 10px;
  z-index: 10;
  position: absolute;
}
@media (min-width: 740px) and (max-width: 1439px) {
  .container {
    width: 100%;
    height: 572px;
  }
  .backgroundImg {
    display: block;
    margin-top: 210px;
    background-size: 100% 316px;
  }
  .videoContainer {
    width: 600px;
    height: 338px;
    margin-top: 80px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 70px 200px 0px rgba(137, 96, 255, 0.36);
    border-radius: 24px;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 100%;
    height: 806px;
  }
  .backgroundImg {
    margin-top: 130px;
    height: 611px;
    display: block;
    background-size: 100% 611px;
  }
  .videoContainer {
    width: 800px;
    height: 450px;
    margin-top: 80px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 70px 200px 0px rgba(137, 96, 255, 0.36);
    border-radius: 24px;
  }
}
