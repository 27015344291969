.container {
  margin-top: 20px;
  width: 335px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  border-radius: 24px;
  border: 2px solid rgba(93, 79, 172, 0.3);
  box-sizing: border-box;
  padding: 15px;
}
.stepNumber {
  width: 100%;
  font-family: Inter;
  font-size: 64px;
  font-weight: 500;
  line-height: 90px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(207.65deg, #ffa800 65.23%, #7c66f4 85.27%);
  -webkit-background-clip: text;
  color: transparent;
  background-clip: text;
  margin: 0;
  padding: 0;
}

.stepHeader {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin: 0;
  padding: 0;
}
.stepInfoText {
  display: none;
  margin-top: 10px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.stepImg {
  width: 303px;
  height: 248px;
  margin-top: 25px;
  object-fit: contain;
}
.showHideButtons {
  display: block;
}
.qrSection {
  display: none;
}

@media (min-width: 768px) and (max-width: 1439px) {
  .container {
    width: 688px;
    margin-top: 20px;
    /* align-items: flex-start; */
  }
  .stepNumber {
    width: 100%;
    font-family: Inter;
    font-size: 64px;
    font-weight: 500;
    line-height: 90px;
    letter-spacing: 0em;
    text-align: left;
    background: linear-gradient(207.65deg, #ffa800 80%, #7c66f4 93%);
    -webkit-background-clip: text;
    color: transparent;
    background-clip: text;
    margin: 0;
    padding: 0;
  }
  .stepHeader {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    width: 100%;
  }
  .stepHeaderMedia {
    width: 370px;
  }

  .stepImg {
    width: 420px;
    height: 343px;
    margin-top: 25px;
    object-fit: contain;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 412px;
    height: 646px;
    margin-top: 10px;
  }
  .stepHeaderMedia {
    width: 100%;
  }
  .stepImg {
    width: 364px;
    height: 298px;

    object-fit: contain;
  }
  .showHideButtons {
    display: none;
  }
  .qrSection {
    width: 100%;
    height: 82px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
  }
  .qrImg {
    width: 82px;
    height: 82px;
  }
  .qrText {
    width: 195px;
    margin-left: 20px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #d1d1d1;
  }
}
