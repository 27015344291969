.containerPayment {
  box-sizing: border-box;
  width: 230px;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.hrLine {
  width: 312px;
  height: 1px;
  background-color: rgba(232, 237, 255, 1);
  margin-top: 20px;
}
