.cardPayContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.textBlock {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.hrText {
  width: 35%;
  border: 1px solid #E8EDFF
}
.cardDetailsText {
  width: 100px;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #aeacbf;
}
