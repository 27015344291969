.container {
  margin: 0 auto;
  margin-top: 100px;
  width: 329px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.headerText {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 30px;
}

.elementContainer {
  width: 100%;
  min-height: 92px;
  height: fit-content;
  margin-top: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 2px;
  background: linear-gradient(253.49deg, #322e48 0%, #322d4f 100%),
    linear-gradient(
      252.29deg,
      rgba(93, 79, 172, 0.5) 0%,
      rgba(255, 255, 255, 0) 98.51%
    );
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.accordionTitleCont {
  width: 300px;
  height: 90px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
}
.title {
  width: 270px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(246, 245, 247, 1);
}
.accordionImg {
  margin-left: 5px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.accordionBody {
  width: 90%;
  height: fit-content;
  box-sizing: border-box;
  padding-bottom: 20px;
}
.mainText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(197, 191, 203, 1);
}
@media (min-width: 740px) and (max-width: 1439px) {
  .container {
    width: 600px;
  }
  .headerText {
    font-size: 32px;
    line-height: 44px;
  }
  .accordionTitleCont {
    width: 590px;
    justify-content: space-evenly;
  }
  .title {
    width: 520px;
  }
}

@media (min-width: 1440px) {
  .container {
    width: 600px;
  }
  .headerText {
    font-size: 32px;
    line-height: 44px;
  }
  .accordionTitleCont {
    width: 590px;
    justify-content: space-evenly;
  }
  .title {
    width: 520px;
  }
}
