.PlanDetailsContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 5px;
}
