.container {
  margin: 0 auto;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.ImgModuleContainer {
  margin: 0 auto;
  position: relative;
  width: 361px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  box-sizing: border-box;
  background: linear-gradient(to right, rgb(35, 29, 63), rgb(69, 61, 104));
  overflow: hidden;
  position: relative;
  border-right: 2px solid rgb(102, 96, 129);
  padding-bottom: 20px;
}
.ImgModuleContainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgb(35, 29, 63), rgb(102, 96, 129));
}
.ImgModuleContainer::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgb(35, 29, 63), rgb(102, 96, 129));
}

.titleBlock {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.title {
  width: 327px;
  height: fit-content;
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #f6f5f7;
}
.subTitle {
  width: 348px;
  height: fit-content;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  color: #f6f5f7;
  margin-top: 15px;
}

.button {
  width: 173px;
  height: 52px;
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
    linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8960ff 100%);

  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  padding: 10px 16px;
  margin-top: 30px;
}

.buttonText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f6f5f7;
}

.starContainer {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 96px;
  height: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 4px;
}
.star {
  width: 16px;
  height: 16px;
  padding: 1.67px, 1.06px, 1.64px, 1.06px;
}

.bottomText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #c5bfcb;
  margin-top: 30px;
}
.ratingContainer {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.ratingText {
  width: 250px;
  height: fit-content;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f6f5f7;
}

@media (min-width: 740px) {
  .ImgModuleContainer {
    width: 600px;
  }
  .title {
    width: 400px;
    margin-top: 25px;
  }
  .starContainer {
    width: 156px;
    height: 25px;
    gap: 8px;
  }
  .star {
    width: 25px;
    height: 25px;
    padding: 2.6px, 1.65px, 2.56px, 1.65px;
  }
}
