.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}
.reviewBlock {
  width: 361px;
  height: fit-content;
  padding: 24px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border: 1.5px solid #696680;
  border-radius: 12px;
  background: #151320;
  margin-bottom: 70px;
}
.reviewTitle {
  width: fit-content;
  height: fit-content;
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #ffffff;
  margin-top: 10px;
}
.rateContainer {
  width: 100%;
  height: 23px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}
.reviewStar {
  width: 24px;
  height: 23px;
  margin-right: 10px;
}

.reviewContainer {
  margin-top: 20px;
}
.review {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}
.reviewerInfo {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.reviewer {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #e8edff;
}
.buttonStyle {
  position: absolute;
  bottom: 20px;
  width: 361px;
  height: 48px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border: 0;
  border-radius: 50px;
  background-color: #484dff;
  color: #f7f8fa;
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
}
.buttonText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;

  color: white;
}
@media (min-width: 320px) and (max-width: 360px) {
  .reviewBlock {
    width: 315px;
  }
  .buttonStyle {
    width: 315px;
  }
}
@media (min-width: 740px) and (max-width: 1099px) {
  .container {
    margin-top: 5%;
  }
  .reviewBlock {
    width: 550px;
    align-items: flex-start;
  }
  .buttonStyle {
    width: 90%;
  }
}

@media (min-width: 1100px) {
  .container {
    margin-top: 10%;
  }
  .reviewBlock {
    width: 550px;
    align-items: flex-start;
  }
}
