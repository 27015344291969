.container {
  margin: 0 auto;
  margin-top: 100px;
  width: 100%;
  height: 1050px;
  display: flex;
  flex-flow: column nowrap;
}

.headerCont {
  width: 100%;
  height: 180px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.headerText {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}

.mainCont {
  width: 239px;
  height: 89px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.mainText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
}

@media (min-width: 740px) and (max-width: 1439px) {
  .container {
    height: 1200px;
  }
  .headerText {
    font-size: 32px;
    line-height: 44px;
  }
  .mainCont {
    width: 553px;
    height: fit-content;
  }
  .ratingPosition {
    margin-left: 20px;
    order: 2;
  }
}

@media (min-width: 1440px) {
  .container {
    height: 1300px;
  }
  .headerText {
    font-size: 40px;
    line-height: 48px;
  }
  .mainCont {
    width: 553px;
    height: fit-content;
  }
  .ratingPosition {
    margin-left: 20px;
    order: 2;
  }
}
