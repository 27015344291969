.bfContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column wrap;
  position: relative;
}
.blurContainer {
  width: 500px;
  height: 500px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: radial-gradient(
    circle at 30% 50%,
    rgba(100, 120, 200, 0.4) 0%,
    rgba(80, 60, 120, 0.3) 45%,
    rgba(255, 255, 255, 0) 100%
  );
  filter: blur(60px);
  z-index: -1;
}
@media (min-width: 715px) and (max-width: 1159px) {
  .blurContainer {
    width: 700px;
    height: 500px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: radial-gradient(
      circle at 30% 50%,
      rgba(100, 120, 200, 0.4) 0%,
      rgba(80, 60, 120, 0.3) 45%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: blur(60px);
    z-index: -1;
  }
}
@media (min-width: 1160px) {
  .blurContainer {
    width: 900px;
    height: 600px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: radial-gradient(
      circle at 30% 50%,
      rgba(100, 120, 200, 0.4) 0%,
      rgba(80, 60, 120, 0.3) 45%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: blur(60px);
    z-index: -1;
  }
}
