.container {
  width: 622px;
  height: 465px;
  margin-left: 100px;
  position: relative;
}

.jonasContainer {
  width: 209px;
  height: 196px;
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 10;
}
.jonasTextImgCont {
  width: 209px;
  height: 124px;

  border-radius: 16px;
  border: 2px;
  background: linear-gradient(
      72.72deg,
      rgba(40, 37, 53, 0.95) 0%,
      rgba(58, 46, 110, 0.95) 100%
    ),
    linear-gradient(
      89.88deg,
      rgba(255, 255, 255, 0) 0.1%,
      rgba(255, 255, 255, 0.2) 99.9%
    );
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}
.jonasImg {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  position: absolute;
  top: -65px;
}

.jonasText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 15px;
}

.mindPalaceCont {
  width: 210px;
  height: 124px;

  border-radius: 16px;
  border: 2px;
  background: linear-gradient(
      72.72deg,
      rgba(40, 37, 53, 0.95) 0%,
      rgba(58, 46, 110, 0.95) 100%
    ),
    linear-gradient(
      89.88deg,
      rgba(255, 255, 255, 0) 0.1%,
      rgba(255, 255, 255, 0.2) 99.9%
    );

  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 70px;
  right: 40px;
}
.memoryPalaceImg {
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.palaceText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 5px;
}

.medalImg {
  width: 138px;
  height: 139px;
  object-fit: cover;
  position: absolute;
  top: 170px;
  z-index: 10;
  animation-delay: -0.2s;
}

.joystickImg {
  width: 113px;
  height: 97px;
  object-fit: cover;
  position: absolute;
  top: 60px;
  right: 60px;
  z-index: 10;
  animation-delay: 1s;
}

.heroSectionImg {
  width: 630px;
  height: 520px;
  object-fit: cover;
  position: absolute;
  top: 5px;
  left: 10px;
}

@keyframes floatUp {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes floatDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
}

.joystickImg {
  animation: floatUp infinite alternate;
  animation-duration: 4s;
}

.medalImg {
  animation: floatDown infinite alternate;
  animation-duration: 4s;
}

@media (max-width: 1440px) {
  .container {
    display: none;
  }
}
