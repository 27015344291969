.shadowContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: rgba(19, 19, 19, 1);
  box-sizing: border-box;
  z-index: 99988;
  cursor: pointer;
}

.ScrollContainer {
  position: relative;
  /* width: 361px; */
  /* height: 1300px; */
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  touch-action: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
}
.ScrollContainer::-webkit-scrollbar {
  display: none;
}

.container {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  width: 361px;
  height: 551px;
  border-radius: 16px;
  border: 1px solid #5d4fac;
  background: #151320;
  padding: 0;
  z-index: 99999;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.topTextContainer {
  width: 313px;
  height: 96px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-wrap: break-word;
  margin-top: 20px;
}
.greenTextTop {
  font-family: Inter;
  font-size: 40px;
  font-weight: 700;
  line-height: 48.41px;
  text-align: center;
  color: #4beeb3;
}
.mainTextTop {
  font-family: Inter;
  font-size: 40px;
  font-weight: 700;
  line-height: 48.41px;
  text-align: center;
  color: #ffffff;
  word-wrap: break-word;
}
.mainTextContainer {
  width: 291px;
  height: 48px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.grayTextMain {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #d1c1e5;
}
.whiteTextMain {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}
.moImgDownsale {
  width: 270px;
  height: 260px;
  object-fit: contain;
}
.button {
  width: 313px;
  height: 48px;
  border-radius: 100px;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: #484dff;
  margin-top: 20px;
  border: none;
  cursor: pointer;
}
.buttonText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
}
