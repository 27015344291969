.container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border-top: 2px solid #2d2948;
  border-bottom: 2px solid #2d2948;
  margin-top: 50px;
  padding-bottom: 50px;
}
.imgTextContainer {
  width: 335px;
  height: 300px;
  position: relative;
  top: 0;
}
.iphoneFull {
  position: absolute;
  top: 0;
  width: 310px;
  height: 280px;
  object-fit: contain;

  z-index: 10;
}
.iphoneShadow {
  display: none;
}

.gamepadContainer {
  width: 142px;
  height: 96px;
  border-radius: 10px;
  background: linear-gradient(
    72.72deg,
    rgba(40, 37, 53, 0.95) 0%,
    rgba(58, 46, 110, 0.95) 100%
  );
  border: 1px solid;

  border-image-source: linear-gradient(
    89.88deg,
    rgba(255, 255, 255, 0) 0.1%,
    rgba(255, 255, 255, 0.2) 99.9%
  );
  position: absolute;
  bottom: 100px;
  right: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 20;
}
.gamepadImg {
  width: 32px;
  height: 32px;
}
.gameText {
  margin-top: 5px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #f6f5f7;
}
.headerBtnText {
  width: 335px;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: #f6f5f7;
}
.headerQRBtnText {
  display: none;
}
.qrImg {
  display: none;
}
@media (min-width: 768px) and (max-width: 1439px) {
  .container {
    width: 688px;
    height: fit-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border-top: 2px solid #2d2948;
    border-right: 2px solid #2d2948;
    border-bottom: 2px solid #2d2948;
    border-left: 1px solid #2d2948;
    border-radius: 24px;
    margin-top: 50px;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .iphoneFull {
    position: absolute;
    top: 0;
    width: 310px;
    height: 280px;
    object-fit: contain;

    z-index: 10;
  }
  .iphoneShadow {
    display: block;
    position: absolute;
    top: 10px;
    right: 70px;
    width: 310px;
    height: 280px;
    object-fit: contain;
  }
  .headerBtnText {
    width: 624px;
    height: 64px;
    font-weight: 600;
    text-align: center;
    color: #f6f5f7;
    margin: 0;
    padding: 0;
    margin-top: 10px;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 1276px;
    height: 500px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    border-top: 2px solid #2d2948;
    border-right: 2px solid #2d2948;
    border-bottom: 2px solid #2d2948;
    border-left: 1px solid #2d2948;
    border-radius: 24px;
    margin-top: 50px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    background: linear-gradient(
      90deg,
      rgba(41, 31, 75, 0.25) 0%,
      #151320 68.01%
    );
  }
  .iphoneFull {
    position: absolute;
    top: -150px;
    left: -210px;
    width: 600px;
    height: 580px;
    object-fit: contain;

    z-index: 10;
  }
  .gamepadContainer {
    width: 176px;
    height: 124px;
    bottom: 130px;
    left: 170px;
  }
  .headerBtnText {
    display: none;
  }
  .headerQRBtnText {
    display: block;
    width: 593px;
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    padding: 0;
    margin-top: 10px;
  }
  .buttonQRContainer {
    display: flex;
    flex-flow: column wrap;
  }
  .buttonQR {
    margin-top: 30px;
    width: 516px;
    height: fit-content;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .qrImg {
    display: block;
    width: 124px;
    height: 124px;
    object-fit: contain;
    margin-right: 40px;
  }
}
