.videoPlayer {
  margin: 0 auto;
  margin-bottom: 30px;
  width: 290px;
  height: 160px;
  object-fit: cover;
  border-radius: 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
@media (min-width: 740px) and (max-width: 1099px) {
  .videoPlayer {
    margin: 0 auto;
    width: 600px;
    height: 333px;
    object-fit: cover;
    box-shadow: 0px 70px 200px 0px rgba(137, 96, 255, 0.36);
    border-radius: 24px;
    margin-bottom: 40px;
  }
}

@media (min-width: 1100px) {
  .videoPlayer {
    width: 430px;
    height: 243px;
    object-fit: cover;
    box-shadow: 0px 70px 200px 0px rgba(137, 96, 255, 0.36);
    border-radius: 24px;
  }
}
