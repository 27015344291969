.containerDiscount {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(21, 19, 32, 0.9);
  box-sizing: border-box;
  z-index: 900;
}
.topLogo {
  display: block;
}
.topContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
}

.containerDiscountButton {
  width: fit-content;
  height: fit-content;
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
    linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8960ff 100%);

  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  padding: 10px 16px;
}

.containerDiscountButtonText {
  color: rgba(246, 245, 247, 1);
  text-align: center;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  line-height: 16px;
}
.devMode {
  position: absolute;
  left: 5px;
  border: none;
  width: 70px;
  height: 30px;
  border-radius: 5px;
  background-color: #6442ec;
  color: whitesmoke;
  cursor: pointer;
}
@media screen and (max-width: 375px) {
  .containerDiscountButtonText {
    font-size: 11px;
  }
}
@media (max-width: 768px) {
  .containerDiscount {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .containerDiscount {
    justify-content: space-around;
  }
}
