.blockContainer {
  margin-top: 50px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

.videoText {
  margin: 0 auto;
  margin-bottom: -20px;
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
}

.videoSliderContainer {
  width: 100%;
  height: 320px;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.videoContainer {
  margin-top: 50px;
  width: 329px;
  height: 185px;
  border-radius: 16px;
  box-sizing: border-box;

  background: linear-gradient(253.49deg, #322e48 0%, #322d4f 100%),
    linear-gradient(
      252.29deg,
      rgba(93, 79, 172, 0.5) 0%,
      rgba(255, 255, 255, 0) 98.51%
    );

  position: relative;
  box-shadow: 0px 16px 60px 0px rgba(137, 96, 255, 0.3);
}

.buttonContainer {
  position: absolute;
  width: 100px;
  height: fit-content;
  z-index: 20;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  bottom: 0;
  right: 50px;
}
.buttonArrowL {
  width: 44px;
  height: 44px;
  border-radius: 50%;

  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: linear-gradient(253.49deg, #3a2e7d 0%, #322d4f 100%),
    linear-gradient(
      252.29deg,
      rgba(93, 79, 172, 0.5) 0%,
      rgba(255, 255, 255, 0) 98.51%
    );

  border: 1px solid rgba(93, 79, 172, 0.5);
  transition: 0.5s;
}
.buttonArrowL:hover {
  background: linear-gradient(253.49deg, #3a2e7d 0%, #7266b4 100%),
    linear-gradient(
      252.29deg,
      rgba(93, 79, 172, 0.5) 0%,
      rgba(255, 255, 255, 0) 98.51%
    );
}
.buttonArrowR {
  width: 44px;
  height: 44px;
  border-radius: 50%;

  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  background: linear-gradient(253.49deg, #3a2e7d 0%, #322d4f 100%),
    linear-gradient(
      252.29deg,
      rgba(93, 79, 172, 0.5) 0%,
      rgba(255, 255, 255, 0) 98.51%
    );

  border: 1px solid rgba(93, 79, 172, 0.5);
  transition: 0.5s;
}
.buttonArrowR:hover {
  background: linear-gradient(253.49deg, #7266b4 0%, #322d4f 100%),
    linear-gradient(
      252.29deg,
      rgba(93, 79, 172, 0.5) 0%,
      rgba(255, 255, 255, 0) 98.51%
    );
}
.arrowImg {
  width: 8px;
  height: 15px;
}
.shadowRightCont {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 10%;
  height: 100%;
  background: linear-gradient(
    270deg,
    #151320 6.49%,
    rgba(21, 19, 32, 0) 95.19%
  );
  z-index: 10;
}
.shadowLeftCont {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 10%;
  height: 100%;
  rotate: 180deg;
  background: linear-gradient(
    270deg,
    #151320 6.49%,
    rgba(21, 19, 32, 0) 95.19%
  );
  z-index: 10;
}

.playerContainer {
  width: 329px;
  height: 185px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

@media (min-width: 740px) and (max-width: 1439px) {
  .videoText {
    margin: 0 auto;
    margin-bottom: -70px;

    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }

  .buttonContainer {
    right: 200px;
  }
  .shadowLeftCont {
    display: none;
  }
  .shadowRightCont {
    display: none;
  }
  .videoSliderContainer {
    margin-top: 50px;
    width: 100%;
    height: 500px;
  }

  .videoContainer {
    width: 600px;
    height: 338px;
  }
  .playerContainer {
    width: 600px;
    height: 338px;
  }
}

@media (min-width: 1440px) {
  .videoText {
    margin: 0 auto;
    margin-bottom: -45px;

    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
  }
  .videoSliderContainer {
    margin-top: 50px;
    width: 100%;
    height: 600px;
  }
  .videoContainer {
    width: 800px;
    height: 450px;
  }
  .buttonContainer {
    right: 200px;
  }
  .shadowLeftCont {
    display: block;
  }
  .shadowRightCont {
    display: block;
  }
  .playerContainer {
    width: 800px;
    height: 450px;
  }
}
