.leftSection {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
@media (min-width: 1160px) {
  .leftSection {
    align-items: flex-start;
  }
}
