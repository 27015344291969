.Loader {
  margin: 0 auto;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}

.LoaderContent {
  z-index: 1;
}
.Progressbar {
  width: 190px;
  height: 190px;
}

.TextContainer {
  margin: 0 auto;
  width: 330px;
  margin-top: 20px;
  height: fit-content;
}

.HeaderTextContainer {
  margin: 0 auto;
  margin-top: 20px;
  width: 320px;
  min-height: 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.TextHeader {
  min-height: 30px;
  color: var(--blue-10, #e8edff);
  text-align: center;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.hrLine {
  margin: 0 auto;
  margin-top: 10px;
  width: 330px;
  height: 1px;
  border-top: 1px solid rgba(232, 237, 255, 0.2);
}
.TextMain {
  width: 320px;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 20px;
}
.TextMainHeader {
  color: var(--green, #4beeb3);
  text-align: center;
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  margin-top: 20px;
}
.TextMainFooter {
  color: var(--white, #fff);
  text-align: center;
  font-size: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
