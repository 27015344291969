.container {
  margin: 0 auto;
  margin-top: 15px;
  width: 329px;
  height: fit-content;
  box-sizing: border-box;
  padding-bottom: 15px;
  padding-top: 19px;

  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.mainText {
  width: 100%;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: rgba(197, 191, 203, 1);
}
.hightText {
  color: rgba(197, 191, 203, 1);
  text-align: left;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
}
.subscrText {
  color: rgba(197, 191, 203, 1);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  text-decoration-line: underline;
}

@media (min-width: 740px) and (max-width: 1439px) {
  .container {
    min-width: 600px;
  }
  .mainText {
    text-align: center;
  }
}

@media (min-width: 1440px) {
  .container {
    width: 600px;
  }
  .mainText {
    text-align: center;
  }
}
