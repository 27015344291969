.buttonContainer {
  margin-top: 20px;
  width: 303px;
  height: 116px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
}
.dwnlBtnStore {
  width: 303px;
  height: 52px;
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #696680;
  cursor: pointer;
}
.btnImg {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.btnTextContainer {
  min-width: 100px;
  margin-left: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
.textHeader {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #e8edff;
}
.textFooter {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #e8edff;
}
@media (min-width: 768px) and (max-width: 1439px) {
  .buttonContainer {
    width: 624px;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .dwnlBtnStore {
    width: 306px;
  }
}
@media (min-width: 1440px) {
}
