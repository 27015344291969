.textPart {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.04em;
  text-align: center;
  text-transform: uppercase;
  color: #f2eef7;
}
.topContainer {
  width: 90%;
  min-height: 24px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (min-width: 740px) {
  .topContainer {
    margin-top: 50px;
  }
}
