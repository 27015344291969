.ContainerComponent {
  max-width: 1200px;
  height: fit-content;
  margin: 0 auto;
  padding: 0 0 16px;
  font-family: Inter, sans-serif;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}
@media screen and (min-width: 1100px) {
  .ContainerComponent {
    padding: 0 0 10px;
    height: fit-content;
  }
}
