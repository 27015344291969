.starContainer {
  width: fit-content;
  height: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 4px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.star {
  width: 25px;
  height: 25px;
  padding: 2.6px, 1.65px, 2.56px, 1.65px;
}
