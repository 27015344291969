.shadowContainer {
  margin: 0 auto;
  width: 100%;
  height: 350px;
  position: relative;
}
.leftImg {
  position: absolute;
  bottom: -30px;
  left: -105px;
  width: 250px;
  height: 250px;
}
.rightImg {
  position: absolute;
  bottom: -30px;
  right: -90px;
  width: 220px;
  height: 220px;
}
.container {
  margin: 0 auto;
  width: 335px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.title {
  width: 100%;
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f6f5f7;
}
.button {
  width: 215px;
  height: 52px;
  border-radius: 10px;
  border: none;
  background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%);
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.buttonText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f6f5f7;
}
@media (min-width: 740px) {
  .shadowContainer {
    width: 550px;
    height: 350px;
    position: relative;
  }
  .leftImg {
    width: 250px;
    height: 280px;
  }
  .rightImg {
    width: 250px;
    height: 250px;
  }
  .container {
    width: 550px;
  }
}
