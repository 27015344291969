.InfoTitleContainer {
  margin: 0 auto;
  margin-bottom: 20px;
  width: 328px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.InfoTitleLogo {
  display: block;
  margin-bottom: 20px;
}
.InfoTitleContainerHeader {
  width: 328px;
  font-family: Inter;
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}

.InfoTitleContainerRatingText {
  width: 240px;
  height: fit-content;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.starContainer {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 96px;
  height: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 4px;
}
.star {
  width: 16px;
  height: 16px;
  padding: 1.67px, 1.06px, 1.64px, 1.06px;
}

@media (min-width: 740px) and (max-width: 1439px) {
  .InfoTitleContainer {
    width: 562px;
  }

  .InfoTitleContainerRatingText {
    width: 368px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
  .InfoTitleContainerHeader {
    width: 562px;
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
  }
  .starContainer {
    width: 156px;
    height: 25px;
    gap: 8px;
  }
  .star {
    width: 25px;
    height: 25px;
    padding: 2.6px, 1.65px, 2.56px, 1.65px;
  }
}
@media (min-width: 1440px) {
  .InfoTitleContainer {
    width: 568px;
    justify-content: center;
    align-items: flex-start;
  }
  .InfoTitleContainerHeader {
    width: 568px;
    font-family: Inter;
    font-size: 58px;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
  }

  .InfoTitleContainerRatingText {
    min-width: 340px;
    max-width: 352px;
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
  }
  .starContainer {
    width: 156px;
    height: 25px;
    gap: 8px;
  }
  .star {
    width: 25px;
    height: 25px;
    padding: 2.6px, 1.65px, 2.56px, 1.65px;
  }
  .InfoTitleLogo {
    display: none;
  }
}
