.emailStep {
  display: flex;
  align-items: flex-start;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: fit-content;
  margin-top: 20px;
}
.textAboveInput {
  width: fit-content;
  margin-top: 4px;
  color: var(--dark-grey, #696680);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 8px;
  margin-left: 8px;
}
.customInput {
  box-sizing: border-box;
  padding: 8px;
  border-radius: 8px;
  width: 290px;
  height: 52px;
  font-size: 18px;
  transition: border 0.2s;
  outline: none;
  background: transparent;
  color: white;
  border: 2px solid rgba(197, 191, 203, 1);
}
.customInput::placeholder {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  color: rgba(162, 159, 194, 1);
}
.customInput:focus {
  border: 1px solid rgba(153, 161, 186, 1);
}
.invalidText {
  color: #ed0006;
  text-align: right;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  line-height: 20px;
}
.textUnderInput {
  color: var(--grey, #aeacbf);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-top: 10px;
  margin-left: 1px;
}
.hrLine {
  width: 100%;
  height: 1px;
  margin-top: 8px;
  background: rgba(232, 237, 255, 0.7);
}
@media (min-width: 715px) {
  .customInput {
    width: 575px;
  }
}
