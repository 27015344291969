.container {
  margin: 0 auto;
  margin-top: 100px;
  margin-bottom: 50px;
  width: 329px;
  height: 548px;
  border-top: 2px solid rgba(45, 41, 72, 1);
  border-bottom: 2px solid rgba(45, 41, 72, 1);

  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;

  overflow: hidden;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.imgsContainer {
  width: 329px;
  height: 344px;
  position: relative;
  top: 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.iphoneFull {
  z-index: 10;
  object-fit: contain;
  width: 100%;
  height: 100%;
  /* box-shadow: 0px 30px 20px 0px rgba(137, 96, 255, 0.2); */
}
.iphoneShadow {
  position: absolute;
  z-index: 5;
  display: none;
  object-fit: contain;
  width: 209px;
  height: 220px;
}

.imgTextCont {
  z-index: 15;
  position: absolute;
  top: 120px;
  right: 0;
  width: 142px;
  height: 96px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.8) 0.1%;

  background: linear-gradient(
      72.72deg,
      rgba(40, 37, 53, 0.95) 0%,
      rgba(58, 46, 110, 0.95) 100%
    ),
    linear-gradient(
      89.88deg,
      rgba(255, 255, 255, 0) 0.1%,
      rgba(255, 255, 255, 0.2) 99.9%
    );

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.gamepad {
  object-fit: contain;
  width: 25px;
  height: 24px;
}
.imgText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}

.textCont {
  margin-top: 30px;
  width: 329px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  padding-bottom: 30px;
}

.headerText {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.mainText {
  margin-top: 20px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
}

@media (min-width: 740px) and (max-width: 1439px) {
  .container {
    width: 492px;

    background: linear-gradient(
        180deg,
        rgba(41, 31, 75, 0.25) 0%,
        #151320 54.89%
      ),
      linear-gradient(
        252.29deg,
        rgba(93, 79, 172, 0.3) 0%,
        rgba(255, 255, 255, 0) 98.51%
      );

    border-radius: 16px;
    border-right: 2px solid rgba(102, 96, 129, 0.7);
    border-top: 1px solid rgba(102, 96, 129, 0.5);
    border-bottom: 1px solid rgba(102, 96, 129, 0.5);
    border-left: 1px solid rgba(98, 94, 116, 0.3);
  }
  .iphoneShadow {
    display: block;
    bottom: 0;
    left: 0;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 1008px;
    height: 500px;
    margin-top: 200px;

    background: linear-gradient(
        180deg,
        rgba(41, 31, 75, 0.25) 0%,
        #151320 54.89%
      ),
      linear-gradient(
        252.29deg,
        rgba(93, 79, 172, 0.3) 0%,
        rgba(255, 255, 255, 0) 98.51%
      );

    border-radius: 16px;
    border-right: 2px solid rgba(102, 96, 129, 0.7);
    border-top: 1px solid rgba(102, 96, 129, 0.5);
    border-bottom: 1px solid rgba(102, 96, 129, 0.5);
    border-left: 1px solid rgba(98, 94, 116, 0.3);

    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
  }
  .iphoneShadow {
    display: block;
    bottom: 0;
    left: 0;
  }
  .headerText {
    font-size: 40px;
    line-height: 48px;
    text-align: left;
  }
  .mainText {
    text-align: left;
  }
}
