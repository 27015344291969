.container {
  margin: 0 auto;
  margin-top: 70px;
  min-width: 340px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: 200px;
}
.missionText {
  width: 329px;
  height: fit-content;
  margin-bottom: 50px;
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.categoryDownloadContainer {
  width: 100%;
  margin-bottom: 150px;
}
.categoryContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}
.categoryStackCont {
  width: 140px;
  display: flex;
  flex-flow: column nowrap;
  margin: 2px;
}
.categoryName {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(246, 245, 247, 1);
  margin-top: 20px;
  margin-bottom: 17px;
}
.categorys {
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
}
.linkCat {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(162, 159, 194, 1);
  margin-bottom: 17px;
  cursor: pointer;
  text-decoration: none;
}
.linkCat:visited {
  color: rgba(162, 159, 194, 1);
}
.linkCat:hover,
.linkCat:active {
  color: rgba(162, 159, 194, 1);
  text-decoration: none;
}
.bottomContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}
.hrLine {
  width: 100%;
  height: 1px;
  position: relative;
}
.hrLine::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: rgba(177, 150, 255, 0.1);
}
.compInfoContainer {
  width: fit-content;
  height: 50px;
  margin-left: 37px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
}
.compInfoLink {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(162, 159, 194, 1);
  margin-right: 10px;
}
.hrVertLine {
  width: 1px;
  height: 20px;
  background: rgba(162, 159, 194, 1);
  margin-right: 10px;
}
.compInfoText {
  width: fit-content;
  height: 50px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-left: 37px;
  margin-bottom: 20px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(162, 159, 194, 1);
}

.downloadContainer {
  display: none;
}

@media screen and (min-width: 715px) {
  .downloadContainer {
    display: block;
  }
  .missionText {
    width: 618px;
    height: fit-content;
    margin-bottom: 50px;
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(246, 245, 247, 1);
  }
  .bottomContainer {
    flex-direction: row wrap;
    justify-content: center;
  }

  .compInfoContainer {
    order: 2;
  }

  .compInfoText {
    order: 1;
  }
}
