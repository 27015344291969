.container {
  position: relative;
  margin-top: 20px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.headerContainer {
  width: 329px;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
}
.iconTextContainer {
  width: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.hrLine {
  width: 107px;
  height: 1px;
  background: rgba(197, 191, 203, 1);
}
.hrLineTwo {
  width: 68px;
  height: 1px;
  background: rgba(197, 191, 203, 1);
}
.headerText {
  margin-left: 3px;
  color: var(--grey, #aeacbf);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.FooterSafe {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 4px 8px;
  gap: 8px;
  width: 221px;
  height: 28px;
  background: rgba(75, 238, 179, 0.2);
  border-radius: 8px;
}
.FooterSafeIcon {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
}
.FooterSafeText {
  width: 180px;
  height: 16px;
  color: var(--green, #4beeb3);
  font-size: 12px;
  font-family: Inter;
  line-height: 16px;
}

.PaymentSystemsContainer {
  margin: 0 auto;
  margin-top: 3px;
  width: 210px;
  height: 30px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
}
