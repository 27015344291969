.container {
  margin: 0 auto;
  margin-top: 60px;
  width: 370px;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.header {
  width: 360px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.headerMainText {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.headerBottomText {
  width: 328px;
  margin-top: 20px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
}
.mainContent {
  margin-top: 50px;
  width: 328px;
  height: fit-content;
  box-sizing: border-box;
  border-radius: 24px;
  border-top: 2px solid rgba(93, 79, 172, 0.5);
  border-left: 2px solid rgba(93, 79, 172, 0.3);
  border-bottom: 2px solid rgba(93, 79, 172, 0.5);
  border-right: 2px solid rgba(93, 79, 172, 0.5);
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: linear-gradient(283.43deg, #2a1f55 -2.25%, #151320 20.58%),
    linear-gradient(
      264.02deg,
      rgba(93, 79, 172, 0.5) 4.74%,
      rgba(93, 79, 172, 0.15) 93.05%
    );
  padding-top: 30px;
}

.partTextContainer {
  margin-top: 30px;
  width: 293px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.mainPartsText {
  margin-bottom: 15px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.buttonContainer {
  margin-top: 30px;
  margin-bottom: 30px;
}

.footerText {
  margin-top: 30px;
  margin-bottom: 100px;
  width: 300px;
  height: 72px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
}
.footerTextSpecial {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-decoration-line: line-through;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ff5900;
}

.videoContainer {
  width: 328px;
  height: 185px;
  margin-top: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 16px 60px 0px rgba(137, 96, 255, 0.3);
  border-radius: 10px;
}

@media (min-width: 715px) and (max-width: 1159px) {
  .header {
    margin-top: 90px;
    width: 460px;
    height: fit-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .headerMainText {
    width: 650px;
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
  }
  .headerBottomText {
    width: 500px;
  }
  .mainContent {
    margin-top: 90px;
    width: 712px;
    height: fit-content;
    padding-top: 30px;
  }
  .partTextContainer {
    width: 90%;
    height: 220px;
    display: flex;
    flex-flow: column nowrap;
  }
  .mainPartsText {
    margin-bottom: 15px;
  }
  .buttonContainer {
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .footerText {
    margin-top: 30px;
    margin-bottom: 80px;
    width: 640px;
    height: fit-content;
  }
  .videoContainer {
    width: 712px;
    height: 400px;
    margin-top: 80px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 70px 200px 0px rgba(137, 96, 255, 0.36);
    border-radius: 24px;
  }
}
@media (min-width: 1160px) {
  .container {
    margin: 0 auto;
    margin-top: 130px;
    width: 370px;
    height: fit-content;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .header {
    width: 700px;
    height: fit-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .headerMainText {
    font-family: Inter;
    font-size: 52px;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(246, 245, 247, 1);
  }
  .headerBottomText {
    width: 500px;
  }
  .mainContent {
    margin-top: 90px;
    width: 712px;
    height: fit-content;
  }
  .partTextContainer {
    margin-top: 35px;
    margin-bottom: 15px;
    width: 620px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }
  .footerText {
    width: 600px;
    height: fit-content;
  }
  .videoContainer {
    width: 712px;
    height: 400px;
    margin-top: 80px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 70px 200px 0px rgba(137, 96, 255, 0.36);
    border-radius: 24px;
  }
}
