.container {
  width: 100%;
  height: 100px;
  margin-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}

.textAboveInput {
  width: fit-content;
  margin-top: 4px;
  color: var(--grey, #d6d4ec);

  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 8px;
  margin-left: 8px;
}
.customInput {
  box-sizing: border-box;
  padding: 8px;
  border: 1px solid rgba(195, 199, 212, 0.2);
  border-radius: 8px;
  width: 100%;
  font-size: 18px;
  transition: border 0.2s;
  outline: none;
  background: transparent;
  color: #ffffff;
}
.customInput::placeholder {
  color: var(--grey, #aeacbf);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.customInput:focus {
  border: 1px solid rgba(153, 161, 186, 1);
}
.invalidText {
  margin: 0 auto;
  color: #e75257;
  width: fit-content;
  height: fit-content;
  margin-top: 2px;
  text-align: center;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  line-height: 20px;
}
.textUnderInput {
  color: var(--grey, #d6d4ec);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  margin-top: 8px;
  margin-left: 5px;
}
