.btnContainer {
    width: 100%;
  }
  .paymentButton {
    width: 329px;
    height: 52px;
  
    border-radius: 10px;
    background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
      linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8960ff 100%);
    border: 2px solid #8960ff;
    cursor: pointer;
  
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  
    position: relative;
  }
  .paymentButton:hover {
    background: linear-gradient(266.9deg, #5936e8 0%, #7369e3 100%),
      linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8764ef 100%);
  }
  .paymentButtonText {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(246, 245, 247, 1);
  }
  .arrow {
    position: absolute;
    right: 14px;
    width: 24px;
    height: 24px;
    background-image: url("./assets/Arrow_right.png");
  }
  