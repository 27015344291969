.container {
  padding: 10px;
}

.toggle {
  position: relative;
  width: 40px;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.toggle.active {
  background-color: #4caf50;
}

.handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s;
}

.toggle.active .handle {
  transform: translateX(20px);
}
