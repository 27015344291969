.ShadowPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 900;
}
.containerUserNameEmailStep {
  position: relative;
  margin-top: 40px;
  z-index: 500;
  width: 370px;
  /* min-height: 460px; */
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #151320, #151320),
    linear-gradient(90deg, rgba(41, 31, 75, 0.25) 0%, #151320 68.01%),
    linear-gradient(
      330.22deg,
      rgba(93, 79, 172, 0.5) 18.17%,
      rgba(255, 255, 255, 0) 80.22%
    );

  box-sizing: border-box;
  padding: 10px;
  border-radius: 16px;
  border-top: 2px solid rgba(93, 79, 172, 0.5);
  border-right: 2px solid rgba(93, 79, 172, 0.8);
  border-bottom: 2px solid rgba(93, 79, 172, 0.5);
  border-left: 2px solid rgba(93, 79, 172, 0.3);
}

.titleContainer {
  position: relative;
  top: 0;
  width: 290px;
  height: 120px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.titleText {
  font-family: Inter;
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.closeButton {
  position: absolute;
  top: 18px;
  right: 20px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  background-color: rgba(246, 245, 247, 0);
  border: none;
  color: white;
  z-index: 400;
}
.noteContainer {
  margin-top: 20px;
  width: 290px;
  height: fit-content;
  /* display: flex;
  flex-flow: row wrap; */
  /* justify-content: center; */
  text-align: center;
  margin-bottom: 20px;
}
.noteText {
  color: var(--grey, #aeacbf);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 160% */
}
.link {
  color: var(--grey, #aeacbf);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-decoration-line: underline;
}
.link:hover {
  text-decoration: none;
}
.buttonStyle {
  position: sticky;
  bottom: 20px;
  border: none;
  width: 290px;
  margin: 0 auto;
  margin-top: 24px;
  padding: 10px 45px;
  border-radius: 8px;
  background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
    linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8960ff 100%);

  color: #f7f8fa;
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
  transition: opacity 0.2s;
  cursor: pointer;
}
.buttonStyleDisable {
  position: sticky;
  bottom: 20px;
  border: none;
  width: 290px;
  margin: 0 auto;
  margin-top: 24px;
  padding: 10px 45px;
  border-radius: 8px;
  background-color: #484dff;
  color: #f7f8fa;
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
  opacity: 0.2;
  transition: 1s;
}

.buttonStyle:hover {
  opacity: 1;
}
@media (min-width: 715px) {
  .containerUserNameEmailStep {
    width: 694px;
    background-color: #f7f8fa;
  }
  .titleContainer {
    position: relative;
    top: 0;
    width: 575px;
    height: 64px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .titleText {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    color: rgba(246, 245, 247, 1);
  }
  .buttonStyle {
    width: 575px;
    background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
      linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8960ff 100%);
  }
  .buttonStyleDisable {
    width: 575px;
  }
  .noteContainer {
    margin-top: 20px;
    width: 575px;
    height: fit-content;
    /* display: flex;
    flex-flow: row wrap; */
    /* justify-content: center; */
    text-align: left;
    margin-bottom: 20px;
  }
  .closeButton {
    position: absolute;
    top: 18px;
    right: 20px;
    width: 15px;
    height: 15px;
    background-color: rgba(246, 245, 247, 0);
    border: none;
    color: white;
    cursor: pointer;
  }
}
