.container {
  margin: 0 auto;
  width: 300px;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.timerText {
  font-family: Inter;
  font-size: 22px;
  font-weight: 700;
  line-height: 24.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f6f5f7;
}

.bottomText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #c5bfcb;
  margin-top: 5px;
}
@media (min-width: 715px) and (max-width: 1159px) {
  .timerText {
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 35.2px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #f6f5f7;
  }
}
@media (min-width: 1160px) {
  .container {
    width: 580px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 120px;
  }
  .timerText {
    font-family: Inter;
    font-size: 36px;
    font-weight: 700;
    line-height: 46.8px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #f6f5f7;
  }
  .bottomText {
    width: 250px;
    height: fit-content;
    font-family: Inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #c5bfcb;
    margin-top: 0;
  }
}
