.container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 15px;
}
.hrLine {
  width: 100%;
  height: fit-content;
  border-top: 1px solid #e8edff;
}

.mainContainer {
  width: 129px;
  height: 26px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: #ecf6f3;
  border-radius: 8px;
  border: none;
  margin-top: 10px;
}
.imgStyle {
  width: 18px;
  height: 18px;
  object-fit: contain;
  margin-right: 5px;
}
.text {
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #00bb77;
}
