.container {
  margin: 0 auto;
  margin-top: 60px;
  width: 370px;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.header {
  width: 360px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.headerMainText {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.headerBottomText {
  margin-top: 20px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
}
.mainContent {
  margin-top: 50px;
  width: 334px;
  height: fit-content;
  box-sizing: border-box;
  border-radius: 24px;
  border-top: 2px solid rgba(93, 79, 172, 0.5);
  border-left: 2px solid rgba(93, 79, 172, 0.3);
  border-bottom: 2px solid rgba(93, 79, 172, 0.5);
  border-right: 2px solid rgba(93, 79, 172, 0.5);
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  background: linear-gradient(283.43deg, #2a1f55 -2.25%, #151320 20.58%),
    linear-gradient(
      264.02deg,
      rgba(93, 79, 172, 0.5) 4.74%,
      rgba(93, 79, 172, 0.15) 93.05%
    );
  padding-top: 30px;
}

.partTextContainer {
  margin-top: 30px;
  width: 293px;
  height: 350px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
.mainPartsText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(246, 245, 247, 1);
}
.btnContainer {
  margin-top: 50px;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
}
.button {
  position: relative;
  width: 267px;
  height: 44px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 12px, 24px, 12px, 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
    linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8960ff 100%);
  z-index: 0;
}
.button:hover {
  box-shadow: 0 0 42px rgba(67, 116, 158, 0.5);
}
.buttonText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(246, 245, 247, 1);
}
.buttonTextDesktop {
  display: none;
}
.btnTextContainer {
  width: 100%;
  height: 100%;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 12px, 24px, 12px, 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
    linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8960ff 100%);
  z-index: 300;
}

.starOneL,
.starOneR {
  width: 80px;
  height: 80px;
  background-size: 80px 80px;
  background-repeat: no-repeat;
}
.starTwoL,
.starTwoR,
.starThreeL,
.starThreeR {
  width: 40px;
  height: 40px;
  background-size: 40px 40px;
  background-repeat: no-repeat;
}
.starOneL,
.starTwoL,
.starThreeL,
.starOneR,
.starTwoR,
.starThreeR {
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s ease-out, transform 0.2s ease-out;
}

.starOneL {
  top: -55px;
  left: -57px;
  background-image: url("./assets/star.png");
  transform: translateX(35px) translateY(30px) rotate(20deg);
  z-index: -10;
}
.starTwoL {
  top: -32px;
  left: 8px;
  background-image: url("./assets/star.png");
  transform: translateX(30px) translateY(22px) rotate(-120deg);
}

.starThreeL {
  top: 3px;
  left: -33px;
  background-image: url("./assets/star.png");
  transform: translateX(30px) translateY(10px) rotate(180deg);
}

.starOneR {
  bottom: -58px;
  right: -50px;
  background-image: url("./assets/star.png");
  transform: translateX(-35px) translateY(-30px) rotate(20deg);
}
.starTwoR {
  bottom: -35px;
  right: 6px;
  background-image: url("./assets/star.png");
  transform: translateX(-30px) translateY(-22px) rotate(-120deg);
}
.starThreeR {
  bottom: -5px;
  right: -35px;
  background-image: url("./assets/star.png");
  transform: translateX(-30px) translateY(-10px) rotate(180deg);
}

.button:hover .starOneL,
.button:hover .starTwoL,
.button:hover .starThreeL,
.button:hover .starOneR,
.button:hover .starTwoR,
.button:hover .starThreeR {
  opacity: 1;
  transform: translateX(0);
}
.underBtnText {
  margin: 0 auto;
  margin-top: 20px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
  text-decoration: underline;
  cursor: pointer;
}
.footerText {
  margin-top: 30px;
  margin-bottom: 35px;
  width: 300px;
  height: 72px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
}

.videoContainer {
  width: 329px;
  height: 185px;
  margin-top: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 16px 60px 0px rgba(137, 96, 255, 0.3);
  border-radius: 10px;
}

@media (min-width: 630px) and (max-width: 1439px) {
  .header {
    margin-top: 90px;
    width: 460px;
    height: fit-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .mainContent {
    margin-top: 90px;
    width: 622px;
    height: 634px;
    padding-top: 30px;
  }
  .partTextContainer {
    margin-top: 35px;
    margin-bottom: 15px;
    width: 90%;
    height: 220px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }
  .videoContainer {
    width: 622px;
    height: 350px;
    margin-top: 80px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 70px 200px 0px rgba(137, 96, 255, 0.36);
    border-radius: 24px;
  }
}
@media (min-width: 1440px) {
  .container {
    margin: 0 auto;
    margin-top: 130px;
    width: 370px;
    height: fit-content;
    box-sizing: border-box;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .header {
    width: 550px;
    height: fit-content;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .headerMainText {
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(246, 245, 247, 1);
  }
  .mainContent {
    margin-top: 90px;
    width: 888px;
    height: 538px;
    box-sizing: border-box;
    border-radius: 24px;
    border-top: 2px solid rgba(93, 79, 172, 0.5);
    border-left: 2px solid rgba(93, 79, 172, 0.3);
    border-bottom: 2px solid rgba(93, 79, 172, 0.5);
    border-right: 2px solid rgba(93, 79, 172, 0.5);
    overflow: hidden;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background: linear-gradient(283.43deg, #2a1f55 -2.25%, #151320 20.58%),
      linear-gradient(
        264.02deg,
        rgba(93, 79, 172, 0.5) 4.74%,
        rgba(93, 79, 172, 0.15) 93.05%
      );
    padding-top: 30px;
  }
  .partTextContainer {
    margin-top: 35px;
    margin-bottom: 15px;
    width: 809px;
    height: 164px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }
  .btnContainer {
    margin-top: 30px;
    margin-bottom: 40px;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-flow: column nowrap;
  }
  .button {
    width: 317px;
    height: 51px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px, 24px, 12px, 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
      linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8960ff 100%);
  }
  .buttonText {
    display: none;
  }
  .buttonTextDesktop {
    display: block;
  }
  .footerText {
    width: 495px;
    height: 72px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(197, 191, 203, 1);
  }
  .videoContainer {
    width: 888px;
    height: 500px;
    margin-top: 80px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 70px 200px 0px rgba(137, 96, 255, 0.36);
    border-radius: 24px;
  }
}
