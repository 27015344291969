.SwiperContainer {
  cursor: pointer;
  /* min-height: 230px; */
  height: 100%;
  /* max-height: fit-content; */
  margin-bottom: 10px;
}
.feedback {
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.feedbackContainer {
  width: 100%;
  /* height: max-content; */
  min-height: 200px;
  height: 100%;
  max-height: 100%;
  border-radius: 16px;
  background: rgba(232, 237, 255, 0.2);
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.feedbackHeader {
  min-width: 230px;
  max-width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.feedbackHeaderImg {
  display: flex;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.feedbackHeaderText {
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  line-height: 24px;
  color: rgba(255, 255, 255, 1);
}
.feedbackRating {
  margin-top: 8px;
  width: 112px;
  height: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
}
.feedbackRatingImg {
  width: 16px;
  height: 16px;
}
.feedbackText {
  margin-top: 8px;
  width: 98%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.feedbackMainText {
  color: var(--blue-10, #e8edff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.feedbackSpecialLineText {
  color: var(--blue-10, #e8edff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.feedbackUserName {
  margin-top: 5px;
  color: var(--blue-10, #e8edff);
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  line-height: 24px;
}
.appReviewImgContainer {
  margin-right: 8px;
}
.appStoreImgContainer {
  display: flex;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 8px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #008cff;
}
.googlePlayImgContainer {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 8px;
  background: var(--white, #fff);
}
.googlePlayImg {
  width: 26px;
  height: 26px;
  background-image: url(./assets/google-play.png);
  background-size: contain;
}