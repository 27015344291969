.container {
  margin: 0 auto;
  margin-top: 20px;
  width: 329px;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}
.elementCont {
  width: 226px;
  height: 212px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 150px;
}
.img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.textContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
}
.title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
  margin-bottom: 20px;
}
.main {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
}
@media (min-width: 740px) and (max-width: 1439px) {
  .container {
    margin-top: 80px;
    width: 492px;
    justify-content: space-evenly;
  }
  .elementCont {
    width: 226px;
    height: 212px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 120px;
}
}
@media (min-width: 1440px) {
  .container {
    margin-top: 110px;
    width: 1024px;
    justify-content: space-evenly;
  }
  .elementCont {
    width: 226px;
    height: 212px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 50px;
  }
}
