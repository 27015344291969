.YourGrandmasterContainer {
  width: 100%;
  height: fit-content;
  margin: 0 auto;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-bottom: 20px;
  margin-top: 60px;
  position: relative;
}
.backgroundImg {
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 385px;
  background-image: url("./assets/Cyber_Egypt_Background.png");
  background-size: cover;
}
.sliderPosition {
  margin-top: 70px;
  width: 100%;
  height: fit-content;
  order: 1;
}
.headerText {
  width: 329px;
  height: fit-content;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
  margin-bottom: 20px;
}
.mainText {
  width: 329px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
  margin-bottom: 20px;
}

.awardsContainer {
  width: 312px;
  height: 264px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;
  align-items: center;
}
.awardElement {
  width: 312px;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;
}
.layerCup {
  width: 24px;
  height: 21px;
  margin-right: 8px;
}
.awardText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(246, 245, 247, 1);
}

.videoContainer {
  width: 329px;
  height: 185px;
  margin-top: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 16px 60px 0px rgba(137, 96, 255, 0.3);
  border-radius: 10px;
  z-index: 10;
}

@media (min-width: 740px) and (max-width: 1439px) {
  .YourGrandmasterContainer {
    width: 100%;
    height: 1351px;
  }
  .sliderPosition {
    order: inherit;
  }
  .backgroundImg {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 385px;
    background-image: url("./assets/Cyber_Egypt_Background.png");
    background-size: cover;
  }
  .headerText {
    width: 664px;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: center;
  }
  .mainText {
    width: 471px;
  }
  .awardsContainer {
    width: 441px;
    height: 344px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    border-right: 2px solid rgb(102, 96, 129);
    border-top: 1px solid rgba(102, 96, 129, 0.5);
    border-bottom: 1px solid rgba(102, 96, 129, 0.5);
    border-left: 1px solid rgba(98, 94, 116, 0.3);
    box-sizing: border-box;
    overflow: hidden;
    background: linear-gradient(300.63deg, #2a1f55 -18.35%, #151320 50.76%),
      linear-gradient(
        264.02deg,
        rgba(93, 79, 172, 0.5) 4.74%,
        rgba(93, 79, 172, 0.15) 93.05%
      );
  }
  .awardElement {
    width: 330px;
    height: fit-content;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
  }
  .awardText {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .videoContainer {
    width: 600px;
    height: 338px;
    margin-top: 80px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 70px 200px 0px rgba(137, 96, 255, 0.36);
    border-radius: 24px;
  }
}
@media (min-width: 1440px) {
  .YourGrandmasterContainer {
    width: 100%;
    height: 1412px;
  }
  .sliderPosition {
    order: inherit;
  }
  .backgroundImg {
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 475px;
    background-image: url("./assets/Cyber_Egypt_Background.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .headerText {
    width: 664px;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0em;
    margin-bottom: 25px;
  }
  .mainText {
    width: 581px;
  }
  .awardsContainer {
    margin-top: 25px;
    width: 800px;
    height: 200px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 24px;
    border-right: 2px solid rgb(102, 96, 129);
    border-top: 1px solid rgba(102, 96, 129, 0.5);
    border-bottom: 1px solid rgba(102, 96, 129, 0.5);
    border-left: 1px solid rgba(98, 94, 116, 0.3);
    box-sizing: border-box;
    overflow: hidden;
    background: linear-gradient(300.63deg, #2a1f55 -18.35%, #151320 50.76%),
      linear-gradient(
        264.02deg,
        rgba(93, 79, 172, 0.5) 4.74%,
        rgba(93, 79, 172, 0.15) 93.05%
      );
    padding-bottom: 20px;
  }
  .awardElement {
    width: 330px;
    height: fit-content;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
  }
  .awardText {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  .videoContainer {
    width: 800px;
    height: 450px;
    margin-top: 80px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 70px 200px 0px rgba(137, 96, 255, 0.36);
    border-radius: 24px;
  }
}
