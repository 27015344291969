.container {
  width: 320px;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 40px;
}
.titlePartOne {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 35.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #28cefe;
}
.titlePartTwo {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 35.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f6f5f7;
}
.bottomText {
  margin-top: 25px;
  width: 320px;
  height: fit-content;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.bottomTextSpecial {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  text-decoration-line: line-through;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ff5900;
}
@media (min-width: 715px) and (max-width: 1159px) {
  .container {
    width: 450px;
    justify-content: center;
  }
  .titlePartOne {
    font-family: Inter;
    font-size: 54px;
    font-weight: 700;
    line-height: 59.4px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .titlePartTwo {
    font-family: Inter;
    font-size: 54px;
    font-weight: 700;
    line-height: 59.4px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .bottomText {
    text-align: center;
    width: 450px;
  }
}
@media (min-width: 1160px) {
  .container {
    width: 658px;
    justify-content: flex-start;
  }
  .titlePartOne {
    font-family: Inter;
    font-size: 68px;
    font-weight: 700;
    line-height: 74.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .titlePartTwo {
    font-family: Inter;
    font-size: 68px;
    font-weight: 700;
    line-height: 74.8px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .bottomText {
    text-align: left;
    width: 450px;
  }
}
