.paymentFormContainer {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  touch-action: auto;
  -webkit-overflow-scrolling: touch;
  background-color: white;
  padding-top: 2px;
}
.paymentFormContainer::-webkit-scrollbar {
  display: none;
}
.paymentForm {
  position: relative;
  background-color: white;
  box-sizing: border-box;
  width: 328px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  /* overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  touch-action: auto;
  -webkit-overflow-scrolling: touch; */
  margin-bottom: 50px;
}

.paymentForm::-webkit-scrollbar {
  display: none;
}

.bottomDetailsContainer {
  width: 100%;
  height: 64px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.textContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.textWithTrial {
  width: 100%;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #aeacbf;
}
.textWithoutTrial {
  width: 100%;
  font-family: Inter;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #aeacbf;
}

@media (min-width: 740px) and (max-width: 1439px) {
  .paymentFormContainer {
    padding-top: 2px;
  }
  .paymentForm {
    width: 532px;
  }
}

@media (min-width: 1440px) {
  .paymentFormContainer {
    padding-top: 2px;
  }
  .paymentForm {
    width: 532px;
  }
}
