.container {
  margin: 0 auto;
  margin-top: 80px;
  position: relative;
  width: 310px;
  height: 300px;
  box-sizing: border-box;
}

.topBlock {
  width: 220px;
  height: 125px;
  border-radius: 16px;
  border: 1px solid rgba(93, 79, 172, 0.5);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.lifetimeAcc {
  font-family: Inter;
  font-size: 28px;
  font-weight: 700;
  line-height: 36.4px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.lifetimeText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #c5bfcb;
}
.awesomeImg {
  position: absolute;
  top: 0;
  right: 70px;
  width: 134px;
  height: 45px;
  z-index: 1;
}
.elipseContainer {
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: 50px;
  right: 90px;
}
.elipse {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.photo {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
}
.union {
  width: 203px;
  height: 155px;
  position: absolute;
  bottom: 8px;
  left: 45px;
  z-index: 4;
}
.unionContainer {
  position: absolute;
  z-index: 10;
  width: 180px;
  height: fit-content;
  rotate: -13deg;
  bottom: 40px;
  left: 56px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.unionTopBloc {
  width: 140px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
}
.unionImg {
  width: 32px;
  height: 32px;
  object-fit: contain;
}
.unionCounter {
  font-family: Inter;
  font-size: 28px;
  font-weight: 700;
  line-height: 36.4px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1be0ff;
}
.unionText {
  width: 150px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #c5bfcb;
  margin-top: 5px;
}
@media (min-width: 715px) {
  .container {
    width: 468px;
    height: 450px;
  }
  .topBlock {
    width: 310px;
    height: 192px;
  }
  .lifetimeAcc {
    font-family: Inter;
    font-size: 46px;
    font-weight: 700;
    line-height: 59.8px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #ffffff;
  }
  .lifetimeText {
    width: 230px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #c5bfcb;
  }
  .awesomeImg {
    right: 120px;
    width: 200px;
    height: 60px;
  }
  .elipseContainer {
    top: 50px;
    right: 140px;
  }
  .elipse {
    width: 140px;
    height: 140px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .photo {
    width: 120px;
    height: 120px;
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 2;
  }
  .union {
    width: 306px;
    height: 235px;
    bottom: 10px;
    left: 50px;
    z-index: 4;
  }
  .unionContainer {
    width: 270px;
    position: absolute;
    bottom: 60px;
    left: 68px;
    height: fit-content;
    z-index: 10;
    rotate: -13deg;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .unionTopBloc {
    width: 210px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
  }
  .unionImg {
    width: 48px;
    height: 48px;
    object-fit: contain;
  }
  .unionCounter {
    font-family: Inter;
    font-size: 46px;
    font-weight: 700;
    line-height: 59.8px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #1be0ff;
  }
  .unionText {
    width: 200px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    color: #c5bfcb;
    margin-top: 5px;
  }
}
