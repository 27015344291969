.PlanDetailsContainer {
  width: 320px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 5px;
}
.HeaderText {
  color: var(--text, #151320);
  text-align: center;
  font-size: 12px;
  font-family: Inter;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.96px;
}
.hrLine {
  margin-top: 7px;
  margin-bottom: 8px;
  width: 300px;
  height: 1px;
  border-top: 1px solid rgb(220, 218, 218);
}
.PlanDetailsTextContainer {
  margin-top: 7px;
  width: 312px;
  height: 20px;
  box-sizing: border-box;
  padding: 0px 8px;
  gap: 4px;

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.Total {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  width: 312px;
  height: 32px;
  background: rgba(75, 238, 179, 0.2);
  border-radius: 8px;
}
.TotalText {
  color: var(--text, #151320);
  font-size: 14px;
  font-family: Inter;
  font-weight: 700;
  line-height: 20px;
}

.PlanDetailsMonth {
  color: var(--dark-grey, #696680);
  font-size: 14px;
  font-family: Inter;
  line-height: 20px;
}
.PlanDetailsDiscount {
  color: var(--dark-grey, #696680);
  font-size: 14px;
  font-family: Inter;
  line-height: 20px;
}
.PlanDetailsDiscountSavePrice {
  color: var(--red, #ff5858);
  text-align: right;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  line-height: 20px;
}
.PlanDetailsTotalText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #151320;
}
.PlanDetailsTextContainerBottom {
  width: 304px;
  height: fit-content;
  margin-top: 5px;
  margin-bottom: 9px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  box-sizing: border-box;
  align-items: flex-end;
}
.TextBottom {
  color: #ed0006;
  text-align: right;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  line-height: 20px;
}
.PlanDetailsTextContainerBottomTrial {
  width: 304px;
  height: fit-content;
  margin-top: 8px;
  /* margin-bottom: 9px; */
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  box-sizing: border-box;
  align-items: center;
}

.TextBottomTrial {
  margin-top: 5px;
  color: var(--green, #4beeb3);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.sevenDayDiscount {
  color: var(--blue-50, #6d8dff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}


