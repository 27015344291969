.container {
  margin: 0 auto;
  width: 276px;
  height: 28px;
  background: rgba(65, 49, 134, 1);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  padding: 4px;
  border-radius: 8px;
}

.text {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(246, 245, 247, 1);
}

@media (min-width: 740px) and (max-width: 1439px) {
  .container {
    width: 276px;
  }
}

@media (min-width: 1440px) {
  .container {
    width: 371px;
    height: 44px;
  }
  .text {
    font-size: 16px;
  }
}
