.container {
  margin: 0 auto;
  width: 370px;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.mainContent {
  margin-top: 50px;
  width: 334px;
  height: fit-content;
  box-sizing: border-box;
  border-radius: 24px;
  border-top: 2px solid rgba(93, 79, 172, 0.5);
  border-left: 2px solid rgba(93, 79, 172, 0.3);
  border-bottom: 2px solid rgba(93, 79, 172, 0.5);
  border-right: 2px solid rgba(93, 79, 172, 0.5);
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(283.43deg, #2a1f55 -2.25%, #151320 20.58%),
    linear-gradient(
      264.02deg,
      rgba(93, 79, 172, 0.5) 4.74%,
      rgba(93, 79, 172, 0.15) 93.05%
    );
  padding-top: 30px;
}
.partTextContainer {
  margin-top: 30px;
  width: 90%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.textParts {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f6f5f7;
  margin-bottom: 20px;
}
.buttonContainer {
  margin-top: 20px;
}

.bottomText {
  width: 284px;
  height: fit-content;
  margin-top: 40px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #c5bfcb;
  margin-bottom: 20px;
}
.bottomTextSpecial {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  text-decoration-line: line-through;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ff5900;
}
@media (min-width: 715px) and (max-width: 1159px) {
  .mainContent {
    width: 712px;
  }
  .bottomText {
    width: 508px;
  }
}
@media (min-width: 1160px) {
  .mainContent {
    width: 1160px;
  }
  .textParts {
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #f6f5f7;
    margin-bottom: 30px;
  }
  .bottomText {
    width: 646px;
  }
}
