.headerContainer {
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  margin-bottom: 10px;
}
.headerText {
  width: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  text-align: center;
  font-family: Inter;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  color: #151320;
  margin-top: 15px;
}
.closePaymentFormButton {
  width: 10px;
  height: 10px;
  cursor: pointer;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  color: #aeacbf;
  text-align: center;
  position: absolute;
  top: 8px;
  left: -5px;
}
.imgCloseButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  object-fit: contain;
  /* background-color: aqua; */
  padding: 0;
  margin: 0;
}
@media (min-width: 740px) and (max-width: 1439px) {
}

@media (min-width: 1440px) {
}
