.button {
  width: 80%;
  height: fit-content;
  border: 0;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
    linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8960ff 100%);

  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  padding: 5px;
  position: relative;
  /* position: absolute;
  top: 5px;
  left: 10px;
  cursor: pointer;
  z-index: 10000; */
}
.buttonText {
  color: rgba(246, 245, 247, 1);
  text-align: center;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  line-height: 16px;
}
.currentDevVariant {
  position: absolute;
  bottom: -10px;
  left: 0px;
  width: 14px;
  height: 14px;
  background-color: rgb(0, 175, 9);
  border-radius: 5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.currentDevVariant {
  color: rgba(246, 245, 247, 1);
  text-align: center;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  line-height: 16px;
}
