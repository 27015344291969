.mainBtnContainer {
  position: relative;
  /* width: 350px;
  min-height: 145px;
  height: fit-content; */
  margin-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: none;
  background-color: inherit;
}

.button {
  position: relative;
  width: inherit;
  height: inherit;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 12px, 24px, 12px, 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
    linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8960ff 100%);
  z-index: 0;
}
.button:hover {
  box-shadow: 0 0 42px rgba(67, 116, 158, 0.7);
}
.buttonText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(246, 245, 247, 1);
}
.underBtnContainer {
  width: fit-content;
  height: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}
.underBtnText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
}
.imgArrow {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.btnTextContainer {
  width: 251px;
  height: 52px;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 12px, 24px, 12px, 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
    linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8960ff 100%);
  z-index: 300;
}
.starOneL,
.starOneR {
  width: 80px;
  height: 80px;
  background-size: 80px 80px;
  background-repeat: no-repeat;
}
.starTwoL,
.starTwoR,
.starThreeL,
.starThreeR {
  width: 40px;
  height: 40px;
  background-size: 40px 40px;
  background-repeat: no-repeat;
}
.starOneL,
.starTwoL,
.starThreeL,
.starOneR,
.starTwoR,
.starThreeR {
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s ease-out, transform 0.2s ease-out;
}

.starOneL {
  top: -55px;
  left: -57px;
  background-image: url("./assets/star.png");
  transform: translateX(35px) translateY(30px) rotate(20deg);
  z-index: -10;
}
.starTwoL {
  top: -32px;
  left: 8px;
  background-image: url("./assets/star.png");
  transform: translateX(30px) translateY(22px) rotate(-120deg);
}

.starThreeL {
  top: 3px;
  left: -33px;
  background-image: url("./assets/star.png");
  transform: translateX(30px) translateY(10px) rotate(180deg);
}

.starOneR {
  bottom: -58px;
  right: -50px;
  background-image: url("./assets/star.png");
  transform: translateX(-35px) translateY(-30px) rotate(20deg);
}
.starTwoR {
  bottom: -35px;
  right: 6px;
  background-image: url("./assets/star.png");
  transform: translateX(-30px) translateY(-22px) rotate(-120deg);
}
.starThreeR {
  bottom: -5px;
  right: -35px;
  background-image: url("./assets/star.png");
  transform: translateX(-30px) translateY(-10px) rotate(180deg);
}

.button:hover .starOneL,
.button:hover .starTwoL,
.button:hover .starThreeL,
.button:hover .starOneR,
.button:hover .starTwoR,
.button:hover .starThreeR {
  opacity: 1;
  transform: translateX(0);
}
