.headSection {
  width: 328px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.topTag {
  width: 137px;
  height: 28px;
  border-radius: 8px;
  background: #0a0918;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.topTagText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.headerText {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 42px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
  margin-top: 10px;
}
.tagImg {
  object-fit: contain;
  width: 18px;
  height: 18px;
  margin-left: 5px;
}
.headerImg {
  object-fit: contain;
  width: 33px;
  height: 33px;
  margin-left: 10px;
}
@media (min-width: 715px) and (max-width: 1159px) {
  .headSection {
    width: 416px;
  }
  .topTag {
    width: 176px;
    height: 28px;
  }
  .topTagText {
    font-size: 16px;
  }
  .headerText {
    font-size: 54px;
    line-height: 72px;
  }
  .tagImg {
    object-fit: contain;
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
  .headerImg {
    object-fit: contain;
    width: 50px;
    height: 50px;
    margin-left: 10px;
  }
}
@media (min-width: 1160px) {
  .headSection {
    width: 493px;
  }
  .topTag {
    width: 176px;
    height: 28px;
  }
  .topTagText {
    font-size: 16px;
  }
  .headerText {
    font-size: 64px;
    line-height: 82px;
  }
  .tagImg {
    object-fit: contain;
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
  .headerImg {
    object-fit: contain;
    width: 60px;
    height: 60px;
    margin-left: 10px;
  }
}
