.container {
  width: 329px;
  height: 70px;
  border-radius: 8px;
  background: #2e2a42;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 40px;
}
.saleImg {
  width: 133px;
  height: 70px;
}
.saleTextContainer {
  width: 189px;
  height: 48px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-left: 15px;
}
.textGreen {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #4beeb3;
}
.textWhite {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #f6f5f7;
}
