.shadowContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 900;
  overflow-y: auto;
}
.ScrollContainer {
  position: relative;
  /* width: 361px; */
  height: 1300px;
  width: 100%;
  /* max-height: 100vh; */
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  touch-action: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
}
.ScrollContainer::-webkit-scrollbar {
  display: none;
}
.container {
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 361px;
  height: 641px;
  border-radius: 16px;
  border: 1px solid #5d4fac;
  background: #151320;
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  touch-action: auto;
  -webkit-overflow-scrolling: touch;
}
.closeButton {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: inherit;
}
.closeButtonImg {
  width: 14px;
  height: 14px;
  object-fit: contain;
}
.topTextContainer {
  width: 341px;
  height: fit-content;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin-top: 20px;
  letter-spacing: -0.35px;
}
.topText {
  font-family: Inter;
  font-size: 29px;
  font-weight: 700;
  line-height: 35.13px;
  text-align: center;
  color: #ffffff;
}
.mainText {
  font-family: Inter;
  font-size: 15.5px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: #d1c1e5;
  margin-top: 10px;
}
.elephantWithHeart {
  width: 315px;
  height: 220px;
  object-fit: contain;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0;
}
.specialPriceContainer {
  width: 318px;
  height: 81px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin-top: 10px;
  margin-bottom: 25px;
  box-sizing: border-box;
}
.priceElement {
  width: 100px;
  height: 55px;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 8px;
  background: linear-gradient(96.39deg, #024960 23.99%, #413186 79.2%);
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.priceElementActive {
  border: 2px solid #ffffff;
}
.textElement {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #f6f5f7;
}
.mostCommon {
  position: absolute;
  bottom: 0;
  right: 108px;
  width: 101px;
  height: 20px;
  border-radius: 8px;
  background: #4beeb3;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.mostCommonText {
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #151320;
}

@media (min-width: 834px) {
  .container {
    width: 500px;
    height: 680px;
  }
  .topTextContainer {
    margin-top: 30px;
    width: 440px;
  }
  .ScrollContainer {
    width: 500px;
  }
  .mainText {
    line-height: 24px;
    margin-top: 20px;
  }
  .elephantWithHeart {
    height: 287px;
    margin-bottom: 0;
  }
  .specialPriceContainer {
    margin-bottom: 23px;
  }
}
