.cardPayContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.payWithCardButton {
  width: 100%;
  height: 45px;
  border-radius: 100px;
  background: #39404e;
  border: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;
}

.payWithCardText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.payWithCardImg {
  width: 32px;
  height: 20px;
  object-fit: contain;
  margin-left: 5px;
}
.cardPayContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.cardDetailsText {
  width: 95%;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #151320;
  margin-bottom: 10px;
}
@media (min-width: 740px) {
  .payWithCardButton {
    height: 55px;
  }
  .payWithCardText {
    font-size: 20px;
  }
}
