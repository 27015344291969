.container {
  width: 335px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  padding-top: 50px;
  margin-top: 40px;
}
.actPlanText {
  position: absolute;
  top: 0;
  margin: 0 auto;
  width: fit-content;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: #f6f5f7;
}
@media (min-width: 768px) and (max-width: 1439px) {
  .container {
    width: 688px;
    margin-top: 10px;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 1276px;
    margin-top: 40px;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-around;
  }
  .actPlanText {
    font-size: 32px;
    line-height: 48px;
  }
}
