.PageComponent {
  width: 100%;
  height: fit-content;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  max-height: fit-content;
  position: relative;
  top: 0;
  box-sizing: border-box;
  font-size: 14px;
}

@media screen and (min-width: 1100px) {
  .pageConPageComponenttainer {
    font-size: 16px;
    max-height: fit-content;
  }
}
