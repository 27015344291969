.container {
  margin: 0 auto;
  margin-top: 70px;

  width: 295px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  background: linear-gradient(90deg, rgba(41, 31, 75, 0.25) 0%, #151320 68.01%),
    linear-gradient(
      252.29deg,
      rgba(93, 79, 172, 0.3) 0%,
      rgba(255, 255, 255, 0) 98.51%
    );

  border-radius: 16px;
  border-right: 2px solid rgb(102, 96, 129);
  border-top: 1px solid rgba(102, 96, 129, 0.5);
  border-bottom: 1px solid rgba(102, 96, 129, 0.5);
  border-left: 1px solid rgba(98, 94, 116, 0.3);

  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;

  overflow: hidden;
}
.headerContainer {
  width: 90%;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
.headerMainText {
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(246, 245, 247, 1);
}
.headerLowText {
  margin-top: 10px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(246, 245, 247, 1);
}
.mainTextContainer {
  width: 90%;
  height: fit-content;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 20px;
}
.mainText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(197, 191, 203, 1);
}
.openingTextContainer {
  width: 90%;
  height: fit-content;
}
.openingText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(197, 191, 203, 1);
}
.textElemParagraph {
  display: block;
  margin-bottom: 20px;
}

.lastTextElem {
  display: inline;
}
.seeMoreButton {
  border: none;
  width: fit-content;
  background-color: inherit;
  padding: 0;
}
.seeMoreLessText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(177, 150, 255, 1);
  cursor: pointer;
}
.seeLessButton {
  border: none;
  width: fit-content;
  background-color: inherit;
  padding: 0;
}
.seeLessText {
  color: var(--green, #4beeb3);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 740px) and (max-width: 1439px) {
  .container {
    width: 600px;
    margin-top: 120px;
  }
}

@media (min-width: 1440px) {
  .container {
    width: 600px;
    margin-top: 120px;
  }
}
