.button {
  width: 80%;
  height: fit-content;
  border: 0;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: linear-gradient(266.9deg, #6442ec 0%, #5f52f1 100%),
    linear-gradient(90deg, rgba(137, 96, 255, 0) 0%, #8960ff 100%);

  cursor: pointer;
  outline: none;
  box-sizing: border-box;
  padding: 5px;
  position: relative;
}
.text {
  color: rgba(246, 245, 247, 1);
  text-align: center;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  line-height: 16px;
}
