.container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.element {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.regularText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6d6b7d;
}
.hr {
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid #e8edff;
  margin-top: 5px;
  margin-bottom: 10px;
}
.totalText {
  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #151320;
}
