.container {
  width: 100%;
  margin-top: 100px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.element {
  width: 257px;
  height: 212px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.headerText {
  margin-bottom: 10px;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(246, 245, 247, 1);
}
.mainText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(197, 191, 203, 1);
}
@media screen and (min-width: 1440px) {
  .container {
    margin: 0 auto;
    width: 1050px;
    margin-top: 100px;
    height: fit-content;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
  }
}
