.elementContainer {
  width: 329px;
  min-height: 100px;
  border-radius: 8px;
  background: linear-gradient(
    253.49deg,
    rgba(68, 61, 105, 0.4) 0%,
    rgba(50, 45, 79, 0.4) 100%
  );
  border: 1px solid #7268a5;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.elementContainerActive {
  background: linear-gradient(96.39deg, #024960 23.99%, #413186 79.2%);
  border: 4px solid #ffffff;
}

.mostPopular {
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 207px;
  height: 25px;
  border-radius: 8px;
  background: #2a2640;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #7268a5;
}
.mostPopularActive {
  border: 1px solid #ffffff;
}
.mostPopularText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.optionContainer {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 12px;
  box-sizing: border-box;
}
.optionHeaderContainer {
  width: fit-content;
  height: fit-content;
}
.optionHeaderText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}
.blockSaveText {
  margin-top: 5px;
  width: 60px;
  height: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

}
.saveTextParts {
  width: fit-content;
  height: inherit;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.trialNotifBlock {
  width: 114px;
  height: 24px;
  border-radius: 8px;
  background: #665b8f;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 12px;
  bottom: 17px;
}
.trialNotifText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.partText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
  margin-right: 3px;
}

.partTextGreen {
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 12.1px;
  text-align: left;
}
