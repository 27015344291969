.container {
  display: none;
  margin: 0 auto;
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 1440px;
  height: 100%;
}

@keyframes floatUp {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@keyframes floatDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
}

.spaceRefill {
  position: absolute;
  top: 0;
  left: 130px;
  width: 203px;
  height: 202px;
  animation-delay: 1s;
}

.medievalScales {
  position: absolute;
  top: 140px;
  left: 330px;
  width: 125px;
  height: 125px;
  animation-delay: 0s;
}

.cyberEgypt {
  position: absolute;
  top: 200px;
  left: 130px;
  width: 187px;
  height: 187px;
  animation-delay: 1s;
}

.spaceSpaceship {
  position: absolute;
  top: 300px;
  left: 120px;
  width: 476px;
  height: 476px;
  animation-delay: -0.5s;
}

.geta {
  position: absolute;
  top: 0;
  right: 120px;
  width: 264px;
  height: 264px;
  animation-delay: -1.5s;
}

.medievalShieldSword {
  position: absolute;
  top: 180px;
  right: 340px;
  width: 172px;
  height: 172px;
  animation-delay: -0.2s;
}

.spaceSpacesuit {
  position: absolute;
  top: 220px;
  right: 120px;
  width: 247px;
  height: 244px;
  animation-delay: -0.4s;
}

.medievalPiano {
  position: absolute;
  bottom: 0;
  right: 220px;
  width: 328px;
  height: 328px;
  animation-delay: 1s;
}

.spaceRefill,
.geta,
.cyberEgypt,
.spaceSpacesuit {
  animation: floatUp infinite alternate;
  animation-duration: 4s;
}

.medievalShieldSword,
.medievalScales,
.spaceSpaceship,
.medievalPiano {
  animation: floatDown infinite alternate;
  animation-duration: 4s;
}

@media (min-width: 1440px) {
  .container {
    display: block;
  }
}
