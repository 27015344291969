.container {
  margin: 0 auto;
  margin-top: 100px;
  width: 328px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.title {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 43.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f6f5f7;
}
.subTitle {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #c5bfcb;
  margin-top: 20px;
}
.videoContainer {
  width: 329px;
  height: 185px;
  margin-top: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 16px 60px 0px rgba(137, 96, 255, 0.3);
  border-radius: 10px;
}
@media (min-width: 715px) and (max-width: 1159px) {
  .container {
    width: fit-content;
  }
  .title {
    width: 500px;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
  }
  .videoContainer {
    width: 710px;
    height: 400px;
    margin-top: 80px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 70px 200px 0px rgba(137, 96, 255, 0.36);
    border-radius: 24px;
  }
}
@media (min-width: 1160px) {
  .container {
    width: fit-content;
    margin-top: 140px;
  }
  .title {
    width: 500px;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
  }
  .videoContainer {
    width: 1160px;
    height: 654px;
    margin-top: 80px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 70px 200px 0px rgba(137, 96, 255, 0.36);
    border-radius: 24px;
  }
}
