.container {
  margin: 0 auto;
  width: 328px;
  height: fit-content;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}
.leftContainer {
  width: 328px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
.title {
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 43.2px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #f6f5f7;
}
.buttonContainer {
  margin-top: 40px;
}

.rightContainer {
  width: 328px;
  height: fit-content;
  background: linear-gradient(283.43deg, #2a1f55 -2.25%, #151320 20.58%);
  border-top: 2px solid rgba(93, 79, 172, 0.5);
  border-right: 2px solid rgba(93, 79, 172, 0.5);
  border-bottom: 2px solid rgba(93, 79, 172, 0.5);
  border-left: 1px solid rgba(93, 79, 172, 0.3);
  border-radius: 24px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}
.elementContainer {
  width: 214px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.lifetimeText {
  font-family: Inter;
  font-size: 64px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #ffffff;
}

.arrowImg {
  width: 18px;
  height: 72px;
  object-fit: contain;
  margin-top: 20px;
  margin-bottom: 20px;
}
.elementContainerTwo {
  width: 214px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.titleContainer {
  width: 214;
  height: fit-content;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.waitlistPeople {
  width: 43px;
  height: 43px;
  margin-right: 5px;
}

.accountsText {
  font-family: Inter;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #1be0ff;
}
.elementText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #c5bfcb;
  margin-top: 20px;
}

@media (min-width: 715px) and (max-width: 1159px) {
  .container {
    width: 712px;
  }
  .leftContainer {
    width: 712px;
  }
  .title {
    width: 600px;
  }
  .rightContainer {
    width: 712px;
  }
}
@media (min-width: 1160px) {
  .container {
    width: 1160px;
    justify-content: space-between;
  }
  .leftContainer {
    width: 557px;
    align-items: flex-start;
  }
  .title {
    width: 500px;
    font-family: Inter;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .rightContainer {
    width: 540px;
  }
}
