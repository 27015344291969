.buttonsContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.payFastText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #151320;
  margin-bottom: 10px;
}
.googleContainerBtn {
  width: 100%;
  height: 45px;
  margin-bottom: 5px;
}
.appleContainerBtn {
  width: 100%;
  height: 45px;
  margin-bottom: 15px;
}

.googlePayPalContainer {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
}

/* .applePayButtonContainer {
  width: 100%;
  height: 43px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 8px;
} */
/* .applePayCopyButton {
  width: 100%;
  height: 43px;
  background-color: black;
  border-radius: 7px;
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
}
.applePayLogo {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 2px;
}
.applePayText {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
} */
@media (min-width: 740px) and (max-width: 1439px) {
  .payFastText {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 26px;
  }
  .googleContainerBtn {
    height: 55px;
  }
  .appleContainerBtn {
    height: 55px;
  }
}

@media (min-width: 1440px) {
  .payFastText {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 26px;
  }
  .googleContainerBtn {
    height: 55px;
  }
  .appleContainerBtn {
    height: 55px;
  }
}
