.container {
  width: 329px;
  height: fit-content;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 30px;
  box-sizing: content-box;
}
.sliderContainer {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.thirdStepsContainer {
  display: flex;
  width: fit-content;
  height: 32px;
  padding: 4px 12px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: rgba(232, 237, 255, 0.2);
  margin-right: 8px;
  margin-top: 8px;
  white-space: nowrap;
  animation: marquee 2s linear infinite;
}

/* @keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
} */

.thirdStepsHeaderText {
  color: var(--blue-10, #e8edff);
  font-family: Inter;
  font-size: 16px;
  font-style: bold;
  font-weight: 700;
  line-height: 24px;
}
.thirdStepsText {
  color: var(--white, #fff);
  font-family: Inter;
  font-size: 13px;
  font-style: medium;
  font-weight: 500;
  line-height: 20px;
}
@media (min-width: 740px) and (max-width: 1439px) {
  .container {
    width: 600px;
  }
}
@media (min-width: 1440px) {
  .container {
    width: 671px;
  }
}
