.prizesContainer {
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
  width: 329px;
  height: fit-content;
  box-sizing: border-box;
  display: flex;
  padding: 24px 16px;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}
.prizesFrame {
  width: 280px;
  min-height: 100px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-top: 20px;
}
.prizesFrameImg {
  width: 231px;
  height: fit-content;
  margin-bottom: 30px;
}
@media (min-width: 740px) and (max-width: 1439px) {
  .prizesContainer {
    width: 600px;
  }
}
@media (min-width: 1440px) {
  .prizesContainer {
    width: 1170px;
  }
}
