.emailStep {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
}

.columnContainer {
  width: 90%;
}
.titleContainer {
  font-family: Inter, sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 20px;
}
.imgContainer {
  width: 100%;
}
.imgStyle {
  max-width: 100%;
}
.copyConteiner {
  margin-top: 20px;
  font-size: 18px;
}

.customInput {
  padding: 16px;
  border: 1px solid rgba(153, 161, 186, 0.2);
  border-radius: 8px;
  width: 100%;
  margin-top: 10px;
  font-size: 18px;
  transition: border 0.2s;
  outline: none;
  background: transparent;
  color: #fff;
}
.customInput:focus {
  border: 1px solid rgba(153, 161, 186, 1);
}
/* input {
  -webkit-user-select: auto !important;
  -khtml-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  -o-user-select: auto !important;
  user-select: auto !important;  
} */

.invalidText {
  margin-left: 90px;
  color: #ed0006;
  text-align: right;
  font-size: 12px;
  font-family: Inter;
  font-weight: 600;
  line-height: 20px;
}
.buttonStyle {
  position: sticky;
  bottom: 20px;
  border: none;
  width: 100%;
  margin: 0 auto;
  margin-top: 25px;
  margin-right: 6px;
  padding: 10px 45px;
  border-radius: 50px;
  background-color: #484dff;
  color: #f7f8fa;
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
  transition: opacity 0.2s;
  cursor: pointer;
}
.buttonStyleDisable {
  position: sticky;
  bottom: 20px;
  border: none;
  width: 100%;
  margin: 0 auto;
  margin-top: 25px;
  margin-right: 6px;
  padding: 10px 45px;
  border-radius: 50px;
  background-color: #484dff;
  color: #f7f8fa;
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
  opacity: 0.2;
  transition: 1s;
}

.buttonStyle:hover {
  opacity: 1;
}
.noteContainer{
  font-size: 12px;
  line-height: 20px;
  color: #999;
  margin-top: 16px;
}
.link{
  color: #999;
  
}
.link:hover {
  text-decoration: none;
}
@media screen and (min-width: 1100px) {
  .emailStep {
    flex-direction: row;
  }
  .columnContainer {
    width: 48%;
    margin-bottom: none;
  }
  .titleContainer {
    font-size: 36px;
    line-height: 40px;
  }
}
